import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getSystemTodaySponcerStats,
  getSystemTotalSponcerStats,
} from "./helpers/apiFunctions";
import Loader from "./components/Loader";
import NoReacord from './components/NoReacord';
const SystemSponcerStats = (props) => {
  const [zigzag, setZigzag] = React.useState();
  const [todayZigzag, setTodayZigzag] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    console.log(props.hex_user);
    getSystemTodaySponcerStats(props.hex_user)
      .then((d) => {
        console.log(d);
        setTodayZigzag(d);
        getSystemTotalSponcerStats(props.hex_user)
          .then((data) => {
            setZigzag(data);
            setTimeout(() => setLoading(false), 2000);
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Today zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Today Sponsor Bonus Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Geomatric name</strong>
                      </td>
                      <td>
                        <strong>Geomatric Symobl</strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Count</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>
                    {todayZigzag?.map((d, index) => (
                      <ZigZagCard {...d} key={index} />
                    ))}
                    {todayZigzag?.length === 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- all zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Total Sponsor Bonus Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Geomatric name</strong>
                      </td>
                      <td>
                        <strong>Geomatric Symobl</strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Count</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>
                    {zigzag?.map((d, index) => (
                      <ZigZagCard {...d} key={index} />
                    ))}
                    {zigzag?.length === 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  function ZigZagCard(props) {
    let arr = [
      { title: "Pentagonal", icon: "penta" },
      { title: "Hexagonal", icon: "hexa" },
      { title: "Heptagonal", icon: "hepta" },
      { title: "Octagonal", icon: "octa" },
      { title: "Nonagonal", icon: "nona" },
      { title: "Decagonal", icon: "deca" },
    ];
    return (
      <tr className="gray zoom">
        <td>{arr[props.level - 1].title}</td>
        <td>
          <img
            src={`/auth/images/geom/${arr[props.level - 1].icon}.png`}
            width="50"
            height="50"
          />
        </td>
        <td>{props.total_entry} </td>
        <td>{props.total_invest} BTT</td>
        <td>{props.total_execution} </td>
        <td>{props.total_income} BTT </td>
      </tr>
    );
  }
};


export default SystemSponcerStats
// export default ZigzagStatic;
