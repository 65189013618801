import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./components/Loader";
import { getUserReEntry } from "./helpers/apiFunctions";
import NoReacord from './components/NoReacord';
export const SystemEntry = (props) => {
  const [systemEntry, setSystemEntry] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getUserReEntry(props.user_hex)
      .then((d) => {
        console.log("System Re-Entry", d);
        setSystemEntry(d);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div className="col-12 col-lg-12 mt-3">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="card-title">Total System Re-Entry</h6>
          </div>
          <div className="card-content">
            <div className="card-body p-0 table-responsive">
              <table className="table">
                <tbody id="transactionHistoryTrns">
                  <tr>
                    <td>
                      <strong>SR.No</strong>
                    </td>
                    <td>
                      <strong>date</strong>
                    </td>
                    <td>
                      <strong>wallet</strong>
                    </td>
                  </tr>
                  {systemEntry?.map((d, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{d.date}</td>
                      <td>{d.user}</td>
                    </tr>
                  ))}
                {systemEntry?.length===0?<NoReacord colspan={3} message={"No Data Found!"}/>:null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
};



export default SystemEntry
