import React from 'react'
import NoReacord from './components/NoReacord';

export default function ExchangeTnx(props) {
    const [excTxn,setExcTxn]=React.useState([]);
    // const [] = React.useState();
    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">RECENT TRANSACTIONS</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>BNB</strong>
                      </td>
                      <td>
                        <strong>Buy/Sell Rate</strong>
                      </td>
                      <td>
                        <strong>Total BNB</strong>
                      </td>
                      <td>
                        <strong>Fees</strong>
                      </td>
                      <td>
                        <strong>Buy/Sell</strong>
                      </td>
                      <td>
                        <strong>Net BNB</strong>
                      </td>
                    </tr>
                    {
                      excTxn?.map((d, index) => {
                        return (
                          <tr className="gray zoom" key={index}>
                            <td>{d.date.split(" ")[0]}</td>
                            <td>
                              {d.txId.substr(0, 10)}...{d.txId.substr(-5)}
                            </td>
                            <td>
                              <img
                                src={`/auth/images/zig/${d.level}.png`}
                                height="50"
                                width="50"
                              />
                            </td>
                            <td>{d.amount} BTT</td>
                            <td>
                              <a
                                href={`https://tronscan.org/#/transaction/${d.txId}`}
                                style={{ color: "red" }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {excTxn?.length == 0 ? (
                      <NoReacord colspan={7} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

    )
}
