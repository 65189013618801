import React, { useEffect, useRef } from "react";
import Sketch from "react-p5";
import { useSelector } from "react-redux";

let breakdown;
let pentagon;
let pentagon_clickable;
let pentagon_clicked;
let inceptive;
let climbing;
let resonance;
let skyward;
let exodus;
let hexagon;
let heptagon;
let octagon;
let nonagon;
let decagon;
let recycle;
let clickable;
const block_height = 50;
const block_width = 80;
const start_x = 10;
const start_y = 140;
const fill_color = "#1d1d21";
const filled_fill_color = "#90c226";

export default function ProgressBar(props) {
  const { progress } = useSelector((state) => state.user.value);

  const ref = useRef();

  const preload = (p5) => {
  
    breakdown = p5?.loadImage("images/imgs/head.png");
    pentagon = p5?.loadImage("images/imgs/penta_light.png");
    pentagon_clickable = p5?.loadImage(
      "images/imgs/penta_clickable_light.png"
    );
    pentagon_clicked = p5?.loadImage(
      "/images/imgs/penta_clicked_light.png"
    );
    inceptive = p5?.loadImage("images/imgs/SYS (1).png");
    climbing = p5?.loadImage("images/imgs/GROWTH (1).png");
    resonance = p5?.loadImage("images/imgs/STABILITY (1).png");
    skyward = p5?.loadImage("images/imgs/WEALTH (1).png");
    exodus = p5?.loadImage("images/imgs/ASSET (1).png");
    hexagon = p5?.loadImage("images/imgs/hexa_light.png");
    heptagon = p5?.loadImage("images/imgs/hepta_light.png");
    octagon = p5?.loadImage("images/imgs/octa_light.png");
    nonagon = p5?.loadImage("images/imgs/nona_light.png");
    decagon = p5?.loadImage("images/imgs/deka_light.png");
    recycle = p5?.loadImage("images/imgs/recycle.png");
  };

  const setup = (p5, canvasParentRef) => {
   
    p5.createCanvas(580, 200).parent(canvasParentRef);
    clickable = new Button(
      start_x + block_width * 1 + block_width / 2 - block_width / 4,
      start_y + block_height / 2 - block_height / 3,
      block_width / 2,
      block_height / 1.5,
      "pentagon",
      pentagon_clickable,
      p5,
      progress > 1 ? true : false,
      progress == 1 ? true : false,
      props.onPenta
    );
    p5.noLoop();
  };

  const draw = (p5) => {
   
    p5.background("#00000000");
    p5.noStroke();

    // first
    p5.fill(fill_color);
    if (progress >= 1) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x, start_y, block_width, block_height);
    new Button(
      start_x + block_width / 2 - block_width / 3,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "breakdown",
      breakdown,
      p5,
      progress > 0 ? true : false,
      progress == 0 ? true : false,
      props.onBreakDown
    ).show();

    // second
    if (progress >= 2 || progress == 0) {
      if (progress >= 2) p5.fill(filled_fill_color);
      clickable.setClickableBackground(pentagon);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 1, start_y, block_width, block_height);
    clickable.show();

    if (progress >= 3) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 1 + block_width / 2 - block_height / 2,
      start_y - block_width,
      block_height,
      block_width
    );

    if (progress >= 3) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 1 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width,
      block_height,
      10,
      0,
      0,
      0
    );
    new Button(
      start_x +
        block_width * 1 +
        block_width / 2 -
        block_height / 2 +
        block_width / 3,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "hexagon",
      hexagon,
      p5
    ).show();

    if (progress >= 4) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 2 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width,
      block_height
    );
    new Button(
      start_x +
        block_width * 2 +
        block_width / 2 -
        block_height / 2 +
        block_width / 3,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "heptagon",
      heptagon,
      p5
    ).show();

    if (progress >= 5) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 3 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width,
      block_height
    );
    new Button(
      start_x +
        block_width * 3 +
        block_width / 2 -
        block_height / 2 +
        block_width / 3,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "octagon",
      octagon,
      p5
    ).show();

    if (progress >= 6) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 4 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width,
      block_height
    );
    new Button(
      start_x +
        block_width * 4 +
        block_width / 2 -
        block_height / 2 +
        block_width / 3,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "nonagon",
      nonagon,
      p5
    ).show();

    if (progress >= 7) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 5 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width,
      block_height
    );
    new Button(
      start_x +
        block_width * 5 +
        block_width / 2 -
        block_height / 2 +
        block_width / 3,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "decagon",
      decagon,
      p5
    ).show();

    if (progress >= 8) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(
      start_x + block_width * 6 + block_width / 2 - block_height / 2,
      start_y - block_width - block_height,
      block_width - block_width / 2 + block_height / 2,
      block_height
    );
    new Button(
      start_x +
        block_width * 6 +
        block_width / 2 -
        block_height / 2 +
        block_width / 4,
      start_y -
        block_width -
        block_height +
        block_height / 2 -
        block_height / 4,
      block_width / 3,
      block_width / 3,
      "recycle",
      recycle,
      p5
    ).show();

    if (progress >= 3) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 2, start_y, block_width, block_height);
    new Button(
      start_x + block_width * 2 + block_width / 6,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "inceptive",
      inceptive,
      p5
    ).show();

    if (progress >= 4) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 3, start_y, block_width, block_height);
    new Button(
      start_x + block_width * 3 + block_width / 6,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "climbing",
      climbing,
      p5
    ).show();

    if (progress >= 5) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 4, start_y, block_width, block_height);
    new Button(
      start_x + block_width * 4 + block_width / 2 - block_width / 3,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "resonance",
      resonance,
      p5
    ).show();

    if (progress >= 6) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 5, start_y, block_width, block_height);
    new Button(
      start_x + block_width * 5 + block_width / 2 - block_width / 3,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "skyward",
      skyward,
      p5
    ).show();

    if (progress >= 7) {
      p5.fill(filled_fill_color);
    } else {
      p5.fill(fill_color);
    }
    p5.rect(start_x + block_width * 6, start_y, block_width, block_height);
    new Button(
      start_x + block_width * 6 + block_width / 2 - block_width / 3,
      start_y + block_height / 2 - block_height / 4,
      block_width / 1.5,
      block_height / 2,
      "exodus",
      exodus,
      p5
    ).show();
    p5.loop();
  };

  return <Sketch setup={setup} draw={draw} preload={preload} ref={ref} />;
}
class Button {
  constructor(
    x,
    y,
    w,
    h,
    type,
    background,
    p5,
    isClicked = false,
    canClick = false,
    callF
  ) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.isClicked = isClicked;
    this.canClick = canClick;
    this.brightness = 0;
    this.type = type;
    this.background = background;
    this.p5 = p5;
    this.callF = callF;
  }

  clicked(px, py) {
    if (
      px > this.x &&
      px < this.x + this.w &&
      py > this.y &&
      py < this.y + this.h &&
      (this.type == "pentagon" || this.type == "breakdown") &&
      this.canClick == true
    ) {
      console.log("clicked");
      if (this.type == "pentagon") {
        this.background = pentagon_clicked;
        this.show();
      }
      this.callF();
      this.canClick = false;
    }
  }
  setClickableBackground(background) {
    if (this.type == "pentagon") this.background = background;
  }
  show() {
    this.p5.image(this.background, this.x, this.y, this.w, this.h);
  }
}
