import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  zigzagStatics,
  todayZigzagStatics,
} from "../pages/helpers/apiFunctions";
import Loader from "./components/Loader";
import NoReacord from "./components/NoReacord";
import { getuserStageIncome } from "./helpers/api";
import { Pagination } from "@mui/material";
import moment from "moment/moment";

export const ZigzagStatic = (props) => {
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const { walletAddress } = useSelector((state) => state.user.value);
  const [zigzag, setZigzag] = React.useState();
  const [loading, setLoading] = React.useState(true);
  function getDirectData(p) {
    getuserStageIncome(walletAddress, p)
      .then((d) => {
        // console.log("Downline Data", d);
        setZigzag(d?.data);
        setTotal(d.totalData);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getDirectData(page);
  }, [page, walletAddress]);

  const handleChange = (e, p) => {
    getDirectData(p);

    setPage(p);
  };
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Today zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title"> Stage Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>#</strong>
                      </td>
                      <td>
                        <strong>Stage Level</strong>
                      </td>
                      <td>
                        <strong>Stage Symobl</strong>
                      </td>
                      {/* <td>
                        <strong>Entry Count</strong>
                      </td> */}
                      {/* <td>
                        <strong>Investment Count</strong>
                      </td> */}
                      <td>
                        <strong>Received Address</strong>
                      </td>
                      <td>
                        <strong>Received Amount</strong>
                      </td>
                      <td>
                        <strong>Received at</strong>
                      </td>
                    </tr>
                    {zigzag?.map((d, index) => (
                      <ZigZagCard data={d} index={index} key={index} />
                    ))}

                    {zigzag?.length == 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
                <hr color="#cbb42a" />
                <div className="d-sm-flex text-center justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(total / 10)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- all zigzag stats--> */}
      </>
    );
};

function ZigZagCard(props) {
  console.log(props);
  return (
    <tr className="gray zoom">
      <td>{props.index + 1}</td>
      <td>Level {props.data.level}</td>
      <td>
        <img
          src={`./images/zig/${props.data.level}.png`}
          width="50"
          height="50"
        />
      </td>

      {/* <td>{props.data.total_entry} </td>
      <td>{props.data.total_invest} BTT</td> */}
      <td>
        {props.data.whoseStageUpgrade.slice(0, 6) +
          "..." +
          props.data.whoseStageUpgrade.slice(-6)}
      </td>
      <td>{props.data.amountRecv / 1e18} ARB </td>
      <td> {moment(Number(props.data.block_timestamp) * 1000).fromNow()}</td>
    </tr>
  );
}

export default ZigzagStatic;
// export default ZigzagStatic;
