import React from "react";
import { Link } from "react-router-dom";
export default function Breadcrumb(props) {
  return (
    <div className="row">
      <div className="col-12  align-self-center">
        <div className="sub-header mt-3 py-3 align-self-center d-sm-flex w-100 rounded">
          <div className="w-sm-100 mr-auto text-white">
            <h4 className="mb-0">{props.title}</h4>
            <p>Welcome to liner WYscale</p>
          </div>
          <ol className="breadcrumb bg-transparent align-self-center m-0 p-0">
            <li className="breadcrumb-item text-white">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">{props.current}</li>
          </ol>
        </div>
      </div>
    </div>
  );
}
