import React, { useEffect } from "react";
import {
  getTopInvestorData,
  getTodayTopInvestorData,
} from "./helpers/apiFunctions";
import Loader from "./components/Loader";
import NoReacord from './components/NoReacord';

export const TopInvestor = (props) => {
  const [topInvestor, setTopInvestor] = React.useState();
  const [todayTopInvestor, settodayTopInvestor] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getTopInvestorData().then((d) => {
      console.log("top investor", d);
      setTopInvestor(d);
      getTodayTopInvestorData()
        .then((d) => {
          console.log("today top", d);
          settodayTopInvestor(d);
          setTimeout(() => setLoading(false), 1000);
        })
        .catch((e) => {
          console.log(e);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- 24 hrs investor--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Last 24 Hrs. Top Investor</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Zigzag Position</strong>
                      </td>
                      <td>
                        <strong>Geometric Position</strong>
                      </td>
                      <td>
                        <strong>Refferal Count</strong>
                      </td>
                      <td>
                        <strong>Joining Investment</strong>
                      </td>
                      <td>
                        <strong>Zigzag Investment</strong>
                      </td>
                      <td>
                        <strong>Geometric Investment</strong>
                      </td>
                      <td>
                        <strong>Total Investment</strong>
                      </td>
                    </tr>
                    {todayTopInvestor?.map((d, index) => {
                      return <Card {...d} index={index} key={index} />;
                    })}
                    {todayTopInvestor?.length === 0 ? (
                      <NoReacord colspan={10} message={"No Record Found !"} />
                    ):null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- System Investor --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Top System Investor</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Zigzag Position</strong>
                      </td>
                      <td>
                        <strong>Geometric Position</strong>
                      </td>

                      <td>
                        <strong>Refferal Count</strong>
                      </td>
                      <td>
                        <strong>Joining Investment</strong>
                      </td>
                      <td>
                        <strong>Zigzag Investment</strong>
                      </td>
                      <td>
                        <strong>Geometric Investment</strong>
                      </td>
                      <td>
                        <strong>Total Investment</strong>
                      </td>
                    </tr>
                    {topInvestor?.map((d, index) => {
                      return <Card {...d} index={index} key={index} />;
                    })}
                    {topInvestor?.length == 0 ? (
                      <NoReacord colspan={10} message={"No Record Found !"} />
                    ):null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

function Card(props) {
  let wallet =props.user_wallet;
  let arr = [
    { title: "Pentagonal", icon: "penta" },
    { title: "Hexagonal", icon: "hexa" },
    { title: "Heptagonal", icon: "hepta" },
    { title: "Octagonal", icon: "octa" },
    { title: "Nonagonal", icon: "nona" },
    { title: "Decagonal", icon: "deca" },
  ];
  return (
    <tr className="zoom">
      <td>{props.index + 1}</td>
      <td>{props.id}</td>
      <td>
        <a href={`https://tronscan.org/#/address/${wallet}`} target="_blank" rel="noreferrer">
          {wallet.substr(0, 5)}...{wallet.substr(-5)}
          <i className="fas fa-external-link-alt"></i>
        </a>
      </td>
      <td>
        <img src={`/auth/images/zig/${props.zigzag}.png`} width="50" height="50" />
      </td>
      <td>
        {props.matrix !== 0 && (
          <img
            src={`/auth/images/geom/${arr[props.matrix - 1].icon}.png`}
            width="50"
            height="50"
          />
        )}
      </td>
      <td style={{ color: "green" }}>{props.refCount} </td>
      <td style={{ color: "green" }}>{props.joiningInvestment} BTT </td>
      <td style={{ color: "green" }}>{props.zigzagInvestment} BTT </td>
      <td style={{ color: "green" }}>{props.matrixInvestment} BTT </td>
      {/* <td style={{ color: "green" }}>{props.sponsorIncome}</td> */}
      <td style={{ color: "green" }}>{props.totalIncome} BTT </td>
    </tr>
  );
}
export default TopInvestor;
