import React, { useEffect } from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Pagination } from "@mui/material";
import NoReacord from "./components/NoReacord";
import { getTotalTeam } from "./helpers/api";

export const Downline = (props) => {
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const { walletAddress } = useSelector((state) => state.user.value);
  const [downlineData, setDownlineData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  function getTeamData(p) {
    getTotalTeam(walletAddress, p)
      .then((d) => {
        // console.log("Downline Data", d);
        setDownlineData(d?.data);
        setTotal(d.totalMembers);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getTeamData(page);
  }, [page, walletAddress]);

  const handleChange = (e, p) => {
    getTeamData(p);

    setPage(p);
  };
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Downline Structure--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Downline</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>#</strong>
                      </td>
                      <td>
                        <strong>User ID</strong>
                      </td>
                      <td>
                        <strong>User Address</strong>
                      </td>
                      <td>
                        <strong>Level</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                  
                      <td>
                        <strong>Date</strong>
                      </td>
                    </tr>
                    {downlineData?.map((d, index) => {
                      return (
                        <tr className="gray zoom" key={index + "downline"}>
                          <td>{index + 1}</td>
                          <td>{d?.member_user_id}</td>
                          <td>
                            {(d?.user).slice(0, 6) +
                              "..." +
                              (d?.user).slice(-6)}
                          </td>
                          <td>{d.level}</td>
                          <td>{d.total_amount} ARB</td>
                       
                          <td>
                            {moment(Number(d.block_timestamp) * 1000).fromNow()}
                          </td>
                        </tr>
                      );
                    })}
                    {downlineData?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
                <hr color="#cbb42a"/>
                <div className="d-sm-flex text-center justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(total / 10)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Downline;

// export default Downline;
