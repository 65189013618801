import React from "react";

export default class Card extends React.PureComponent {
  render() {
    return (
      <div className="col-12  col-md-6 col-lg-6 mt-3">
        <div className="card border-bottom-0">
          <div
            className={`card-content border-bottom border-${this.props.theme} border-w-5`}
          >
            <div className="card-body p-4">
              <div className="d-flex">
                <div className={`circle-50 outline-badge-${this.props.theme}`}>
                  <span className="cf card-liner-icon cf-xmr mt-2">
                    {this.props.icon}
                  </span>
                </div>
                <div className="media-body align-self-center pl-3">
                  <span className="mb-0 h6 font-w-600">{this.props.title}</span>
                  <br />
                  <p className="mb-0 font-w-500 h6">
                    {this.props.value}{" "}
                    <img
                      src="/images/zig/arbitrum 1.svg"
                      alt=""
                      height={15}
                      width={15}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
