import React, { useEffect } from "react";
import { createChart } from "lightweight-charts";
import './chart.css';
export default function Chart(props) {
  useEffect(() => {
    const chartNode = document.getElementById("chart-lightweight");
    const chart = createChart(chartNode, {
      height: 300,
    });
    const candleCart = chart.addCandlestickSeries();
    const data = [
      { time: "2018-10-19", open: 54.62, high: 55.5, low: 54.52, close: 54.9 },
      {
        time: "2018-10-22",
        open: 55.08,
        high: 55.27,
        low: 54.61,
        close: 54.98,
      },
      {
        time: "2018-10-23",
        open: 56.09,
        high: 57.47,
        low: 56.09,
        close: 57.21,
      },
      { time: "2018-10-24", open: 57.0, high: 58.44, low: 56.41, close: 57.42 },
      {
        time: "2018-10-25",
        open: 57.46,
        high: 57.63,
        low: 56.17,
        close: 56.43,
      },
      {
        time: "2018-10-26",
        open: 56.26,
        high: 56.62,
        low: 55.19,
        close: 55.51,
      },
      {
        time: "2018-10-29",
        open: 55.81,
        high: 57.15,
        low: 55.72,
        close: 56.48,
      },
      { time: "2018-10-30", open: 56.92, high: 58.8, low: 56.92, close: 58.18 },
      {
        time: "2018-10-31",
        open: 58.32,
        high: 58.32,
        low: 56.76,
        close: 57.09,
      },
      {
        time: "2018-11-01",
        open: 56.98,
        high: 57.28,
        low: 55.55,
        close: 56.05,
      },
      {
        time: "2018-11-02",
        open: 56.34,
        high: 57.08,
        low: 55.92,
        close: 56.63,
      },
      {
        time: "2018-11-05",
        open: 56.51,
        high: 57.45,
        low: 56.51,
        close: 57.21,
      },
      {
        time: "2018-11-06",
        open: 57.02,
        high: 57.35,
        low: 56.65,
        close: 57.21,
      },
      {
        time: "2018-11-07",
        open: 57.55,
        high: 57.78,
        low: 57.03,
        close: 57.65,
      },
      { time: "2018-11-08", open: 57.7, high: 58.44, low: 57.66, close: 58.27 },
      { time: "2018-11-09", open: 58.32, high: 59.2, low: 57.94, close: 58.46 },
      { time: "2018-11-12", open: 58.84, high: 59.4, low: 58.54, close: 58.72 },
      {
        time: "2018-11-13",
        open: 59.09,
        high: 59.14,
        low: 58.32,
        close: 58.66,
      },
      {
        time: "2018-11-14",
        open: 59.13,
        high: 59.32,
        low: 58.41,
        close: 58.94,
      },
      {
        time: "2018-11-15",
        open: 58.85,
        high: 59.09,
        low: 58.45,
        close: 59.08,
      },
      {
        time: "2018-11-16",
        open: 59.06,
        high: 60.39,
        low: 58.91,
        close: 60.21,
      },
      {
        time: "2018-11-19",
        open: 60.25,
        high: 61.32,
        low: 60.18,
        close: 60.62,
      },
      {
        time: "2018-11-20",
        open: 61.03,
        high: 61.58,
        low: 59.17,
        close: 59.46,
      },
      { time: "2018-11-21", open: 59.26, high: 59.9, low: 58.88, close: 59.16 },
      { time: "2018-11-23", open: 58.86, high: 59.0, low: 58.29, close: 58.64 },
      {
        time: "2018-11-26",
        open: 58.64,
        high: 59.51,
        low: 58.31,
        close: 59.17,
      },
      { time: "2018-11-27", open: 59.21, high: 60.7, low: 59.18, close: 60.65 },
      { time: "2018-11-28", open: 60.7, high: 60.73, low: 59.64, close: 60.06 },
      {
        time: "2018-11-29",
        open: 59.42,
        high: 59.79,
        low: 59.26,
        close: 59.45,
      },
      { time: "2018-11-30", open: 59.57, high: 60.37, low: 59.48, close: 60.3 },
      { time: "2018-12-03", open: 59.5, high: 59.75, low: 57.69, close: 58.16 },
      { time: "2018-12-04", open: 58.1, high: 59.4, low: 57.96, close: 58.09 },
      {
        time: "2018-12-06",
        open: 58.18,
        high: 58.64,
        low: 57.16,
        close: 58.08,
      },
      {
        time: "2018-12-07",
        open: 57.91,
        high: 58.43,
        low: 57.34,
        close: 57.68,
      },
      { time: "2018-12-10", open: 57.8, high: 58.37, low: 56.87, close: 58.27 },
      { time: "2018-12-11", open: 58.77, high: 59.4, low: 58.63, close: 58.85 },
      {
        time: "2018-12-12",
        open: 57.79,
        high: 58.19,
        low: 57.23,
        close: 57.25,
      },
    ];
    chart.timeScale().fitContent();
    candleCart.setData(data);
  });
  return (
    <>
      <div className="sc-dTdPqK dAzftb">
        <div height="32px" className="sc-bdVaJa rSmgz">
          <div height="24px" width="12px" className="sc-bdVaJa gSxurx"></div>
          <div className="sc-bdVaJa lmEScu">
            <div className="sc-bdVaJa dveUWY">
              <h1 color="#1C1B21" className="sc-bwzfXH iHECUo">
                BTF/BTT
              </h1>
              <span color="#9b9b9b" className="sc-bwzfXH ksDqJJ">
                BitForce
              </span>
            </div>
            <div className="sc-bdVaJa sc-dliRfk iUXzPH">
              <span color="#929292" className="sc-bwzfXH kgoTtc px-2">
                Last Price
              </span>
              <span cursor="pointer" color="#1C1B21" className="sc-bwzfXH jaArUU">
                ₹8.55
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="chart-lightweight"></div>
    </>
  );
}
