import React from "react";

export default function Hepta(props) {
  // console.log(props,"hepta::")
  return (
    <svg
      width="512"
      height="512"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <g id="hepta">
          <g id="svg_28">
            <polygon
              className="st2"
              points="252,74.0999984741211 107.30000305175781,143.8000030517578 71.60000610351562,300.29998779296875 171.70001220703125,425.8999938964844 332.29998779296875,425.8999938964844 432.4000244140625,300.29998779296875 396.70001220703125,143.8000030517578 "
              id="svg_29"
              fill="#231F20"
            />
            <g id="svg_30">
              <g id="svg_31">
                <g id="svg_32">
                  <path
                    className="st3"
                    d="m252.4,145c-30.2,0 -54.9,24.7 -54.9,54.9s24.7,54.9 54.9,54.9s54.9,-24.7 54.9,-54.9s-24.7,-54.9 -54.9,-54.9z"
                    id="svg_33"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
              <g id="svg_34">
                <g id="svg_35">
                  <path
                    className="st3"
                    d="m346.6,298.7c-1.4,-3.6 -3.4,-7 -5.5,-10.1c-11,-16.3 -28.1,-27.1 -47.2,-29.7c-2.4,-0.2 -5,0.2 -7,1.7c-10.1,7.4 -22.1,11.3 -34.5,11.3s-24.5,-3.8 -34.5,-11.3c-1.9,-1.4 -4.6,-2.2 -7,-1.7c-19.2,2.6 -36.4,13.4 -47.2,29.7c-2.2,3.1 -4.1,6.7 -5.5,10.1c-0.7,1.4 -0.5,3.1 0.2,4.6c1.9,3.4 4.3,6.7 6.5,9.6c3.4,4.6 7,8.6 11,12.5c3.4,3.4 7.2,6.5 11,9.6c18.9,14.1 41.7,21.6 65.2,21.6s46.3,-7.4 65.2,-21.6c3.8,-2.9 7.7,-6.2 11,-9.6c3.8,-3.8 7.7,-7.9 11,-12.5c2.4,-3.1 4.6,-6.2 6.5,-9.6c1.3,-1.5 1.5,-3.2 0.8,-4.6z"
                    id="svg_36"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

        {/* #1 */}
        {props.length >= 1 ? (
          <g id="svg_356">
            <text
              fill="#000000"
              x="141.2647"
              y="472.65292"
              id="svg_55"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              1 .{" "}
              {props.users.length >= 1
                ? props.users[0]?.slice(0, 4) +
                  "..." +
                  props.users[0]?.slice(-4)
                : null}
            </text>
            <g id="svg_58">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="167.20501"
                cy="439.7853"
                id="svg_56"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_57"
                d="m170.94465,438.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {/* #2 */}
        {props.length >= 2 ? (
        <g id="svg_98">
          <text
            fill="#000000"
            x="30.2647"
            y="337.65292"
            id="svg_68"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            2 .{" "}
            {props.users.length >= 2
              ? props.users[1]?.slice(0, 4) + "..." + props.users[1]?.slice(-4)
              : null}
          </text>
          <g id="svg_71">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="56.20501"
              cy="304.7853"
              id="svg_69"
              rx="14.49968"
              ry="13.74969"
              stroke="#000"
            />
            <path
              id="svg_70"
              d="m59.94465,303.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
        </g>
        ) : null}
        {/* #3 */}
        {props.length >= 3 ? (
        <g id="svg_799">
          <g id="svg_76">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="92.20501"
              cy="140.7853"
              id="svg_74"
              rx="14.49968"
              ry="13.74969"
              stroke="#000"
            />
            <path
              id="svg_75"
              d="m95.94465,139.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
          <text
            fill="#000000"
            x="61.2647"
            y="120.65292"
            id="svg_73"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            3 .{" "}
            {props.users.length >= 3
              ? props.users[2]?.slice(0, 4) + "..." + props.users[2]?.slice(-4)
              : null}
          </text>
        </g>
        ) : null}
        {/* #4 */}
        {props.length >= 4 ? (
        <g id="svg_1">
          <text
            fill="#000000"
            x="225.2647"
            y="40.65292"
            id="svg_78"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            4 .{" "}
            {props.users.length >= 4
              ? props.users[3]?.slice(0, 4) + "..." + props.users[3]?.slice(-4)
              : null}{" "}
          </text>
          <g id="svg_81">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="252.20501"
              cy="59.7853"
              id="svg_79"
              rx="14.49968"
              ry="13.74969"
              stroke="#000"
            />
            <path
              id="svg_80"
              d="m255.94465,58.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
        </g>
        ) : null}
        {props.length >= 5 ? (
        <g id="svg_933">
          <text
            fill="#000000"
            x="384.2647"
            y="120.65292"
            id="svg_99"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            5 .{" "}
            {props.users.length >= 5
              ? props.users[4]?.slice(0, 4) + "..." + props.users[4]?.slice(-4)
              : null}
          </text>
          <g id="svg_102">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="412.20501"
              cy="140.7853"
              id="svg_100"
              rx="14.49968"
              ry="13.74969"
              stroke="#000"
            />
            <path
              id="svg_101"
              d="m415.94465,139.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
        </g>
        ) : null}
        {props.length >= 6 ? (
        <g id="svg_104">
          <text
            fill="#000000"
            x="421.2647"
            y="334.65292"
            id="svg_88"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            6 .{" "}
            {props.users.length >= 6
              ? props.users[5]?.slice(0, 4) + "..." + props.users[5]?.slice(-4)
              : null}
          </text>
          <g id="svg_91">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="447.20501"
              cy="301.7853"
              id="svg_89"
              rx="14.49968"
              ry="13.74969"
              stroke="#000"
            />
            <path
              id="svg_90"
              d="m450.94465,300.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
        </g>
        ) : null}
        {props.length >= 7 ? (
        <g id="svg_454">
          <text
            fill="#000000"
            x="315.2647"
            y="470.65292"
            id="svg_93"
            fontSize="16"
            fontFamily="sans-serif"
            textAnchor="start"
            stroke="#cbb42a"
          >
            7 .{" "}
            {props.users.length >= 7
              ? props.users[6]?.slice(0, 4) + "..." + props.users[6]?.slice(-4)
              : null}
          </text>
          <g id="svg_96">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              fill="#fff"
              cx="341.20501"
              cy="438.7853"
              id="svg_94"
              rx="14.49968"
              ry="13.74969"
              stroke="#cbb42a"
            />
            <path
              id="svg_95"
              d="m344.94465,437.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              fill="#000000"
              stroke="#000"
            />
          </g>
        </g>
        ) : null}
      </g>
    </svg>
  );
}
