import axios from "axios";
const Base_url = "https://rwa.wyscale.com/api";

export async function getDirect(userAddress ,page) {
  try {
   
    const response = await axios.get(
      `${Base_url}/direct_member.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}


export async function getTotalTeam(userAddress,page) {
  try {
   
    const response = await axios.get(
      `${Base_url}/my_team.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}


// export async function getuserDirectIncome(userAddress,page) {
//   try {
   
//     const response = await axios.get(
//       `${Base_url}/direct_income.php?user=${userAddress}&page=${page}`
//     );
  
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//     throw error;
//   }
// }

export async function getuserDirectIncome(userAddress,page) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/direct_income.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getuserStageIncome(userAddress,page) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/user_referal_income_from_stage.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getuserGeometryIncome(userAddress,page) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/geometry_update_income.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getuserclaimIncome(userAddress,page) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/cliam_referal_income.php?user=${userAddress}&page=${page}`
    );
  
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getuserRegistraionDetails(userAddress,page) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/user_registration_detail.php?user=${userAddress}&page=${page}`
    );
  
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
export async function getuserRandomIdDetails(randomId) {
  try { 
   
    const response = await axios.get(
      `${Base_url}/userDetail_randomId.php?member_user_id=${randomId}`
    );
   console.log(response.data.data,"dataRan")
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
