import React, { useEffect } from "react";
import Loader from "./components/Loader";
import {
  getUserLevel,
  getUserMatrix,
  getUserTotalTxn,
} from "./helpers/apiFunctions";
import { connect } from "react-redux";
import NoReacord from './components/NoReacord';
export const UserTnx = (props) => {
  const [allTransactions, setAllTransaction] = React.useState();
  const [levelTransaction, setLevelTransaction] = React.useState();
  const [matrixTransaction, setMatrixTransaction] = React.useState();
  const [loading, setLoading] = React.useState(true);
  // useEffect(() => {
  //   console.log(props.user_hex);
  //   getUserLevel(props.user_hex)
  //     .then((d) => {
  //       console.log("user Level", d);
  //       setLevelTransaction(d);
  //       getUserMatrix(props.user_hex)
  //         .then((data) => {
  //           console.log("user Matrix", data);
  //           setMatrixTransaction(data);
  //           getUserTotalTxn(props.user_hex)
  //             .then((data2) => {
  //               console.log("user Total", data2);
  //               setAllTransaction(data2);
  //               setTimeout(() => setLoading(false), 2000);
  //             })
  //             .catch((e) => {
  //               console.log(e);
  //             });
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //         });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Level Transaction --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Level Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>Level</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {
                      levelTransaction?.map((d, index) => {
                        return (
                          <tr className="gray zoom" key={index}>
                            <td>{d.date.split(" ")[0]}</td>
                            <td>
                              {d.txId.substr(0, 10)}...{d.txId.substr(-5)}
                            </td>
                            <td>
                              <img
                                src={`/auth/images/zig/${d.level}.png`}
                                height="50"
                                width="50"
                              />
                            </td>
                            <td>{d.amount} BTT</td>
                            <td>
                              <a
                                href={`https://tronscan.org/#/transaction/${d.txId}`}
                                style={{ color: "red" }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {levelTransaction?.length == 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Matrix Transaction --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Geometric Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>Matrix</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {matrixTransaction?.map((d, index) => {
                      let arr = [
                        { title: "Pentagonal", icon: "penta" },
                        { title: "Hexagonal", icon: "hexa" },
                        { title: "Heptagonal", icon: "hepta" },
                        { title: "Octagonal", icon: "octa" },
                        { title: "Nonagonal", icon: "nona" },
                        { title: "Decagonal", icon: "deca" },
                      ];
                      return (
                        <tr className="gray zoom" key={index}>
                          <td>{d.date.split(" ")[0]}</td>
                          <td>
                            {d.txId.substr(0, 10)}...{d.txId.substr(-5)}
                          </td>
                          <td>
                            <img
                              src={`/auth/images/geom/${arr[d.level - 1].icon}.png`}
                              width="50"
                              height="50"
                            />
                          </td>
                          <td>{d.amount} BTT</td>
                          <td>
                            <a
                              href={`https://tronscan.org/#/transaction/${d.txId}`}
                              style={{ color: "red" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                    {matrixTransaction?.length == 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Total Transaction --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Total Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>From</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {allTransactions?.map((d, index) => {
                      return (
                        <tr className="gray zoom" key={index}>
                          <td>{d.date.split(" ")[0]}</td>
                          <td>
                            {d.txId.substr(0, 10)}...{d.txId.substr(-5)}
                          </td>
                          <td>
                            {d.user_wallet
                              .substr(0, 10)}
                            ...
                            {d.user_wallet.substr(-5)}
                          </td>
                          <td>{d.amount} BTT</td>
                          <td>
                            <a
                              href={`https://tronscan.org/#/transaction/${d.txId}`}
                              style={{ color: "red" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                     {allTransactions?.length == 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};


export default UserTnx
// export default UserTnx;
