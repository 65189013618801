import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
  walletAddress: "",
  userExist: false,
  userInfo:[],
  refresh:false,
  geoLength:0,
  progress:0,
  userApiData:[],
};
const dataSlice = createSlice({
  name: "dataSlice",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setWalletAddress: (state, action) => {
        state.value.walletAddress = action.payload.walletAddress;
    },
    setUserExist: (state, action) => {
      state.value.userExist = action.payload.userExist;
    },
    setUserInfo: (state, action) => {
      state.value.userInfo = action.payload.userInfo;
    },
    setProgress: (state, action) => {
      state.value.progress = action.payload.progress;
    },
    setGeoLength: (state, action) => {
      state.value.geoLength = action.payload.geoLength;
    },
    setUserApiData: (state, action) => {
      
      state.value.userApiData = {...action.payload.userApiData}
    },
  },
});

export const { setUserExist, setWalletAddress,setUserInfo, setGeoLength ,setProgress,setUserApiData} = dataSlice.actions;
export default dataSlice.reducer;



