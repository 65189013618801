import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import Loader from "./components/Loader";
import { systemTopBar } from "./helpers/apiFunctions";
// import PropTypes from 'prop-types'
// import { connect } from 'react-redux'

export const SystemDashboard = (props) => {
  const [systemData, setSystemData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    systemTopBar()
      .then((d) => {
        setSystemData(d);
        console.log(d);
        setLoading(false);
      })
      .catch((e) => {
        NotificationManager.error(e.message, "Error", 2000);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <hr />
        <div className="col-12  col-md-6 col-lg-3 mt-3">
          <div className="card border-bottom-0">
            <div className="card-content border-bottom border-primary border-w-5">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="circle-50 outline-badge-primary">
                    <span className="cf card-liner-icon cf-ltc mt-2"></span>
                  </div>
                  <div className="media-body align-self-center pl-3">
                    <span className="mb-0 h6 font-w-600">System Total User</span>
                    <br />
                    <p className="mb-0 font-w-500 h6">{systemData?.total_user}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12  col-md-6 col-lg-3 mt-3">
          <div className="card border-bottom-0">
            <div className="card-content border-bottom border-warning border-w-5">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="circle-50 outline-badge-warning">
                    <span className="cf card-liner-icon cf-xlm mt-2"></span>
                  </div>
                  <div className="media-body align-self-center pl-3">
                    <span className="mb-0 h6 font-w-600">
                      Total System Transaction
                    </span>
                    <br />
                    <p className="mb-0 font-w-500 h6">{systemData?.total_transaction}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12  col-md-6 col-lg-3 mt-3">
          <div className="card border-bottom-0">
            <div className="card-content border-bottom border-success border-w-5">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="circle-50 outline-badge-success">
                    <span className="cf card-liner-icon cf-link mt-2"></span>
                  </div>
                  <div className="media-body align-self-center pl-3">
                    <span className="mb-0 h6 font-w-600">Total System Earn BTT</span>
                    <br />
                    <p className="mb-0 font-w-500 h6">{systemData?.total_btt_earn} BTT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12  col-md-6 col-lg-3 mt-3">
          <div className="card border-bottom-0">
            <div className="card-content border-bottom border-info border-w-5">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="circle-50 outline-badge-info">
                    <span className="cf card-liner-icon cf-xmr mt-2"></span>
                  </div>
                  <div className="media-body align-self-center pl-3">
                    <span className="mb-0 h6 font-w-600">Total System Earn USD</span>
                    <br />
                    <p className="mb-0 font-w-500 h6">${systemData?.total_usd_earn}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SystemDashboard;
