import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";


import { Provider } from "react-redux";
import { crown } from "./pages/helpers/apiFunctions";
import { gettronWebContract } from "./pages/helpers/helperfunctions";
import { store } from "./pages/helpers/redux/store";

// gettronWebContract();
// crown();
// setInterval(() => crown(), 6000);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
     
        <App />
     
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);


reportWebVitals();
