import React from "react";
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Dashboard from "../pages/Dashboard";
import ContractTxn from "../pages/ContractTxn";
import Downline from "../pages/Downline";
import GeomatricStatic from "../pages/GeomatricStatic";
import ZigzagStatic from "../pages/ZigzagStatic";
import Header from "../pages/components/Header";
import Menu from "../pages/components/Menu";
import Footer from "../pages/components/Footer";
import PromoContent from "../pages/PromoContent";
import SystemDashboard from "../pages/SystemDashboard";
import TopGainer from "../pages/TopGainer";
import TopInvestor from "../pages/TopInvestor";
import TopMarketer from "../pages/TopMarketer";
import UserTnx from "../pages/UserTnx";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Breadcrumb from "../pages/components/Breadcrumb";
import Reff from "../pages/components/Reff";
import SystemGeomatricStatic from "../pages/SystemGeoStats";
import SystemZigzagStats from "../pages/SystemZigZag";
import ReffralLink from "../pages/components/ReffralLink";
import GeomatricStructure from "../pages/GeomatricStructure";
import UserEntryBonus from "../pages/UserEntryBonus";
import ProgressBar from "../pages/components/ProgressBar";
import UserEntry from "../pages/UserEntry";
import SystemEntryBonus from "../pages/SystemEntryBonus";
import SystemEntry from "../pages/SystemEntry";
import UserSponcerStats from "../pages/UserSponcerStats";

import SystemSponcerStats from "../pages/SystemSponsorBonus";
import Direct from "../pages/Direct";
import Exchange from "../pages/Exchange";
import { useEffect } from "react";
import {
  getGeometryStructure,
  getUserInfo,
  getUserProgressInfo,
  isUserExists,
  startNow,
} from "../pages/helpers/getweb3";
import {
  setGeoLength,
  setProgress,
  setUserApiData,
  setUserExist,
  setUserInfo,
  setWalletAddress,
} from "../pages/helpers/redux/dataSlice";
import ClaimBonus from "../pages/ClaimBonus";
import Home from "../pages/Home";
import { Explore } from "../pages/Explore";
import { getuserRegistraionDetails } from "../pages/helpers/api";


function Index() {
  const location = useLocation();
  const history = useHistory();
  const { walletAddress, userExist, userInfo } = useSelector(
    (state) => state.user.value
  );
  const dispatch = useDispatch();

  let title = "";
  let current = "";

  switch (location.pathname) {
    case "/dashboard":
      title = "Dashboard";
      current = "Dashboard";
      break;
    case "/contractTxn":
      title = "Contract Transaction";
      current = "Contract Txn";
      break;
    case "/direct":
      title = "Direct";
      current = "direct";
      break;
    case "/downline":
      title = "Downline";
      current = "Downline";
      break;
    case "/geostats":
      title = "Geometric Stats";
      current = "Geometric Stats";
      break;
    case "/zigzagstats":
      title = "Zigzag Stats";
      current = "Zigzag Stats";
      break;
    case "/promocontent":
      title = "Gallery";
      current = "Gallery";
      break;
    case "/systemdashboard":
      title = "System Dashboard";
      current = "System Dashboard";
      break;
    case "/systemgeostat":
      title = "System Geometric Stats";
      current = "System Geometric Stats";
      break;
    case "/systemzigzagstat":
      title = "System Zigzag Stats";
      current = "System Zigzag Stats";
      break;
    case "/topgainer":
      title = "Top Gainer";
      current = "top gainer";
      break;
    case "/topinvestor":
      title = "Top Investor";
      current = "top investor";
      break;
    case "/geostructure":
      title = "Geomatric Structure";
      current = "Geomatric Structure";
      break;
    case "/topmarketer":
      title = "Top Marketor";
      current = "top marketor";
      break;
    case "/usertnx":
      title = "User Transaction";
      current = "usertnx";
      break;
    case "/sponcerstats":
      title = "Sponcer Bonus Stats";
      current = "sponcerstats";
      break;
    case "/userentrybonus":
      title = "User Entry Bonus ";
      current = "userentrybonus";
      break;
    case "/systementrybonus":
      title = "System Entry Bonus";
      current = "systementrybonus";
      break;
    case "/user-reentry":
      title = "User Re-Entry Report";
      current = "user-reentry";
      break;
    case "/system-reentry":
      title = "System ReEntry Report";
      current = "system-reentry";
      break;
    case "/userentrystats":
      title = "User Entry Bonus Stats";
      current = "userentrystats";
      break;
    case "/systementrystats":
      title = "System Entry Bonus Stats";
      current = "systementrystats";
      break;
    case "/systemsponcerstats":
      title = "System Sponcer Bonus Stats";
      current = "systemsponcerstats";
      break;
    case "/usersponcerstats":
      title = "User Sponcer Bonus Stats";
      current = "usersponcerstats";
      break;
    case "/claimBonus":
      title = "User Claim Bonus Stats";
      current = "claimBonus";
      break;
    case "/exchange":
      title = "Sell/Buy BTT";
      current = "exchange";
      break;
    default:
      title = "";
      current = "";
  }

  const getStoredWalletAddress = () => {
    return localStorage.getItem("wallet_local");
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      const userAddress = accounts[0];
      localStorage.setItem("wallet_local", userAddress);
      dispatch(setWalletAddress({ walletAddress: userAddress }));
    };

    // Add the event listener for accountsChanged
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    // Retrieve the wallet address from localStorage
    const storedWalletAddress = getStoredWalletAddress();

    if (storedWalletAddress) {
      dispatch(setWalletAddress({ walletAddress: storedWalletAddress }));
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);

  useEffect(() => {
    const localWallet = localStorage.getItem("wallet_local");

    if (localWallet) {
      isUserExists(localWallet).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
      getUserInfo(localWallet).then((res) => {
        dispatch(setUserInfo({ userInfo: res }));
      });
      getGeometryStructure(walletAddress, userInfo.inWhichGeo).then((res) => {
        dispatch(setGeoLength({ geoLength: res?.length }));
      });
      getUserProgressInfo(walletAddress).then((res) => {
        dispatch(setProgress({ progress: res }));
      });
    }
  }, [walletAddress]);

  useEffect(() => {
    getuserRegistraionDetails(walletAddress).then((res) => {
      if (res?.length > 0) {
        dispatch(setUserApiData({ userApiData: res[0] }));
      
      }
    });
  }, [walletAddress, userInfo]);

  useEffect(() => {
    setTimeout(() => {
      getuserRegistraionDetails(walletAddress).then((res) => {
        // console.log("setCallll:::");
        if (res?.length > 0) {
          dispatch(setUserApiData({ userApiData: res[0] }));
        }
      });
    }, 150000);
  }, [walletAddress]);
  return (
    <>
      <Route
        exact
        path="/"
        render={() => (userExist ? <Redirect to="/dashboard" /> : <Home />)}
      />
      <Route
        exact
        path="/explore"
        render={() => (userExist ? <Redirect to="/dashboard" /> : <Explore />)}
      />
      {userExist && <Header />}
      {userExist && <Menu />}
      <main className={`${userExist ? "" : "p-0 m-0"}`}>
        <div className="container-fluid site-width">
          {userExist && <Breadcrumb title={title} current={current} />}
          {userExist && <ReffralLink />}
          {userExist && (
            <div className="row">
              <div className="col-md-6 col-lg-6 pt-4">
                <ProgressBar />
              </div>
              <div className="col-md-6 col-lg-6">
                <Reff />
              </div>
            </div>
          )}

          <div className="row">
            <Switch>
              {/* <Route exact path="/" render={() => <Home />} /> */}

              {/* <Route
                exact
                path="/"
                render={() =>
                  userExist ? <Redirect to="/dashboard" /> : <Login />
                }
              /> */}

              <Route
                exact
                path="/login"
                render={() =>
                  userExist ? <Redirect to="/dashboard" /> : <Login />
                }
              />

              <Route
                path="/create/:id?"
                render={(props) =>
                  userExist ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Register {...props} />
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                render={() => (userExist ? <Dashboard /> : <Login />)}
              />
              <Route
                exact
                path="/exchange"
                render={() => (userExist ? <Exchange /> : <Login />)}
              />
              <Route
                exact
                path="/contractTxn"
                render={() => (userExist ? <ContractTxn /> : <Login />)}
              />
              <Route
                exact
                path="/direct"
                render={() => (userExist ? <Direct /> : <Login />)}
              />
              <Route
                exact
                path="/downline"
                render={() => (userExist ? <Downline /> : <Login />)}
              />
              <Route
                exact
                path="/geostats"
                render={() => (userExist ? <GeomatricStatic /> : <Login />)}
              />
              <Route
                exact
                path="/zigzagstats"
                render={() => (userExist ? <ZigzagStatic /> : <Login />)}
              />
              <Route
                exact
                path="/promocontent"
                render={() => (userExist ? <PromoContent /> : <Login />)}
              />
              <Route
                exact
                path="/systemdashboard"
                render={() => (userExist ? <SystemDashboard /> : <Login />)}
              />
              <Route
                exact
                path="/systemgeostat"
                render={() =>
                  userExist ? <SystemGeomatricStatic /> : <Login />
                }
              />
              <Route
                exact
                path="/systemzigzagstat"
                render={() => (userExist ? <SystemZigzagStats /> : <Login />)}
              />
              <Route
                exact
                path="/topgainer"
                render={() => (userExist ? <TopGainer /> : <Login />)}
              />
              <Route
                exact
                path="/topinvestor"
                render={() => (userExist ? <TopInvestor /> : <Login />)}
              />
              <Route
                exact
                path="/topmarketer"
                render={() => (userExist ? <TopMarketer /> : <Login />)}
              />
              <Route
                exact
                path="/usertnx"
                render={() => (userExist ? <UserTnx /> : <Login />)}
              />
              <Route
                exact
                path="/geostructure/"
                render={() => (userExist ? <GeomatricStructure /> : <Login />)}
              />
              <Route
                exact
                path="/userentrybonus"
                render={() => (userExist ? <UserEntryBonus /> : <Login />)}
              />

              <Route
                exact
                path="/systementrybonus"
                render={() => (userExist ? <SystemEntryBonus /> : <Login />)}
              />

              <Route
                exact
                path="/usersponcerstats"
                render={() => (userExist ? <UserSponcerStats /> : <Login />)}
              />
              <Route
                exact
                path="/systemsponcerstats"
                render={() => (userExist ? <SystemSponcerStats /> : <Login />)}
              />
              <Route
                exact
                path="/user-reentry"
                render={() => (userExist ? <UserEntry /> : <Login />)}
              />
              <Route
                exact
                path="/claimBonus"
                render={() => (userExist ? <ClaimBonus /> : <Login />)}
              />
              <Route
                exact
                path="/system-reentry"
                render={() => (userExist ? <SystemEntry /> : <Login />)}
              />
            </Switch>
            {userExist && <Footer />}
          </div>
        </div>
      </main>
    </>
  );
}

export default Index;
