import Index from "./routes/index";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Toaster } from "react-hot-toast";
import "./App.css"
import React from "react";

function App() {
  return (
    <div className="App">
      <NotificationContainer />
      <Index />
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
