import React, { useEffect } from "react";
import Loader from "./components/Loader";
import {
  getTopgainerData,
  getTodayTopgainerData,
} from "./helpers/apiFunctions";
// import { connect } from 'react-redux'
import NoReacord from './components/NoReacord';
export const TopGainer = (props) => {
  const [topGainer, settopGainer] = React.useState();
  const [todayTopGainer, setTodayTopGainer] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getTopgainerData()
      .then((d) => {
        console.log("top gainer", d);
        settopGainer(d);
        getTodayTopgainerData()
          .then((d) => {
            setTodayTopGainer(d);
            console.log("today top gainer", d);
            setTimeout(() => setLoading(false), 1000);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- 24 hrs gainers --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Last 24 Hrs Gianer</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Zigzag Position</strong>
                      </td>
                      <td>
                        <strong>Geometric Position</strong>
                      </td>
                      <td>
                        <strong>Refferal Count</strong>
                      </td>
                      <td>
                        <strong>Entry Income</strong>
                      </td>
                      <td>
                        <strong>Zigzag Income</strong>
                      </td>
                      <td>
                        <strong>Geometric Income</strong>
                      </td>
                      <td>
                        <strong>Sponser Income</strong>
                      </td>
                      <td>
                        <strong>Total Income</strong>
                      </td>
                    </tr>
                    {todayTopGainer?.map((d, index) => (
                      <Card {...d} index={index} key={index} />
                    ))}
                    {todayTopGainer?.length === 0 ? (
                      <NoReacord colspan={11} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- System Gainers  --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">System Top Gainer</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Zigzag Position</strong>
                      </td>
                      <td>
                        <strong>Geometric Position</strong>
                      </td>

                      <td>
                        <strong>Refferal Count</strong>
                      </td>
                      <td>
                        <strong>Entry Income</strong>
                      </td>
                      <td>
                        <strong>Zigzag Income</strong>
                      </td>
                      <td>
                        <strong>Geometric Income</strong>
                      </td>
                      <td>
                        <strong>Sponser Income</strong>
                      </td>
                      <td>
                        <strong>Total Income</strong>
                      </td>
                    </tr>
                    {topGainer?.map((d, index) => {
                      return <Card {...d} index={index} key={index} />;
                    })}
                    {topGainer?.length === 0 ? (
                      <NoReacord colspan={11} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

function Card(props) {
  let wallet = props.user_wallet;
  let arr = [
    { title: "Pentagonal", icon: "penta" },
    { title: "Hexagonal", icon: "hexa" },
    { title: "Heptagonal", icon: "hepta" },
    { title: "Octagonal", icon: "octa" },
    { title: "Nonagonal", icon: "nona" },
    { title: "Decagonal", icon: "deca" },
  ];
  return (
    <tr className="zoom">
      <td>{props.index + 1}</td>
      <td>{props.id}</td>
      <td>
        <a href={`https://tronscan.org/#/address/${wallet}`} target="_blank" rel="noreferrer">
          {wallet.substr(0, 5)}...{wallet.substr(-5)}
          <i className ="fas fa-external-link-alt"></i>
        </a>
      </td>
      <td>
        <img src={`/auth/images/zig/${props.zigzag}.png`} width="50" height="50" />
      </td>
      <td>
        {props.matrix !== 0 && (
          <img
            src={`/auth/images/geom/${arr[props.matrix - 1].icon}.png`}
            width="50"
            height="50"
          />
        )}
      </td>
      <td style={{ color: "green" }}>{props.refCount} </td>
      <td style={{ color: "green" }}>{props.entryIncome} BTT </td>
      <td style={{ color: "green" }}>{props.zizagIncome} BTT </td>
      <td style={{ color: "green" }}>{props.matrixIncome} BTT </td>
      <td style={{ color: "green" }}>{props.sponsorIncome} BTT</td>
      <td style={{ color: "green" }}>{props.totalIncome} BTT </td>
    </tr>
  );
}

// const mapStateToProps = (state) => ({

// })

// const mapDispatchToProps = {

// }

// export default connect(mapStateToProps, mapDispatchToProps)(TopGainer)

export default TopGainer;
