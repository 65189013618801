import React from "react";
import {
  getGeometryStructure,
  getUserInfo,
  getUserProgressInfo,
  upgradeGeometryBuy,
  upgradeStageBuy,
} from "./helpers/getweb3";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeoLength,
  setProgress,
  setUserInfo,
} from "./helpers/redux/dataSlice";
import { getTotalTeam } from "./helpers/api";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useState } from "react";

const SlotBuy = () => {
  const { walletAddress, userInfo, userExist } = useSelector(
    (state) => state.user.value
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  function UpgradeStageEntry() {
    if (walletAddress)
      if (!userInfo.isStagePurchased) {
        upgradeStageBuy(walletAddress, 10, setRefresh, setLoading);
      } else {
        toast.error("Already Upgraded to Stage !");
      }
  }
  function Register() {
    if (walletAddress) toast.error("Already Register!");
  }
  function UpgradeGeometryEntry() {
    if (walletAddress) {
      if (userInfo.isStagePurchased) {
        if (userInfo.inWhichGeo == 0) {
          upgradeGeometryBuy(walletAddress, 25, setRefresh2, setLoading);
        } else {
          toast.error("Already Upgraded to Geometry !");
        }
      } else {
        toast.error("Firstly Upgraded to Stage !");
      }
    }
  }
  useEffect(() => {
    getUserInfo(walletAddress).then((res) => {
      dispatch(setUserInfo({ userInfo: res }));
    });
  }, [refresh, walletAddress, refresh2]);
  useEffect(() => {
    getGeometryStructure(walletAddress, userInfo.inWhichGeo).then((res) => {
      dispatch(setGeoLength({ geoLength: res?.length }));
    });
    getUserProgressInfo(walletAddress).then((res) => {
      dispatch(setProgress({ progress: res }));
    });
  }, [refresh, walletAddress, userInfo, refresh2]);
  return (
    <>
      {/* <div className="col-12 col-md-6 col-lg-3  mt-3"> */}
      <div className="card">
        <div className="card-content">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="card-title"> Start Geometric Income</h6>
          </div>
          <div className="card-body text-center">
            <div className="content">
              <br />
              <p className="mb-0 font-w-500 tx-s-12 ">
                Fix position on geometric for global income. It's 6 layer auto
                entry system once you have fix your position you will get all 6
                layer income.
              </p>
            </div>
            <button
              className="card btn-success my-3 text-left cursor-pointer btn btn-block p-0"
              onClick={() => Register()}
            >
              <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                <img src="./wy.png" width="30" height="30" />
                <div className="card-liner-content">
                  <h2 className="card-liner-title text-white">
                    10 ARB (50% ARB+50% wARB)
                  </h2>
                  <h6 className="card-liner-subtitle text-white">
                    Already Upgraded
                  </h6>
                </div>
                <div className="p-2 m-2">
                  {userExist ? (
                    <i className="fa fa-check-circle fa-3x"></i>
                  ) : (
                    <i className="fas fa-cart-plus fa-3x"></i>
                  )}
                </div>
              </div>
            </button>
            <button
              className="card btn-info my-3 text-left cursor-pointer btn btn-block  p-0"
              onClick={() => UpgradeStageEntry()}
              disabled={loading}
            >
              <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                <img src="./wy.png" width="30" height="30" />
                <div className="card-liner-content">
                  <h2 className="card-liner-title text-white">
                    20 ARB (50% ARB+50% wARB)
                  </h2>
                  <h6 className="card-liner-subtitle text-white">
                    {!userInfo.isStagePurchased
                      ? "Upgrade to Stage Entry"
                      : "Already Upgraded"}
                  </h6>
                </div>
                <div className="p-2 m-2">
                  {userInfo?.isStagePurchased ? (
                    <i className="fa fa-check-circle fa-3x"></i>
                  ) : (
                    <i className="fas fa-cart-plus fa-3x"></i>
                  )}
                </div>
              </div>
            </button>
            <button
              className="card btn-primary my-3 text-left cursor-pointer btn btn-block  p-0"
              onClick={() => UpgradeGeometryEntry()}
              disabled={loading}
            >
              <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                <img src="./wy.png" width="30" height="30" />
                <div className="card-liner-content">
                  <h2 className="card-liner-title text-white">
                    50 ARB (50% ARB+50% wARB)
                  </h2>
                  <h6 className="card-liner-subtitle text-white">
                    {userInfo.inWhichGeo == 0
                      ? "Upgrade to Geometry"
                      : " Already Upgraded"}
                  </h6>
                </div>
                <div className="p-2 m-2">
                  {userInfo?.inWhichGeo > 0 ? (
                    <i className="fa fa-check-circle fa-3x"></i>
                  ) : (
                    <i className="fas fa-cart-plus fa-3x"></i>
                  )}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default SlotBuy;
