const url = "https://bttforce.io/api/api.php";
const cron_full = "https://bttforce.io/api/cron_full.php";
const cron_income = "https://bttforce.io/api/cron_add_income.php";
var controller = new AbortController();

export function ApiRegister(wallet_addr, tx_id, hex_addr) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "register",
      user: wallet_addr,
      tx_id: tx_id,
      hex_addr: hex_addr,
    }),
  })
    .then((res) => res.json())
    .then((res) => console.log(res))
    .catch((e) => console.log(e));
}

// export function ApiLogin(wallet_addr,hex_addr) {
//   return fetch(url, {
//     method: "POST",
//     signal: controller.signal,
//     body: JSON.stringify({
//       action: "login",
//       user: wallet_addr,
//       hex_addr: hex_addr,
//     }),
//   })
//     .then((res) => res.json())
//     .then((res) => console.log(res))
//     .catch((e) => console.log(e));
// }

export function getTopData(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "topBar",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function geoMatrixStatus(hex_user, user_id) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "matrix_status",
      hex_user: hex_user,
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function contractTransaction() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "contractTransaction",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function recentSystemtransaction() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "recentSystemTransaction",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function zigzagStatics(hex_user) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_level_stats",
      hex_user: hex_user,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function todayZigzagStatics(hex_user) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_today_level_stats",
      hex_user: hex_user,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function SystemZigzagStatics() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_level_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function SystemTodayZigzagStatics() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_today_level_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function geomatrixStatics(hex_user) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_matrix_stats",
      hex_user: hex_user,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function todayGeomatrixStatics(hex_user) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_today_matrix_stats",
      hex_user: hex_user,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function SystemGeomatrixStatics() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_matrix_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function todaySystemGeomatrixStatics() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_today_matrix_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserTotalIncome(hex_user) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_total_income",
      hex_user: hex_user,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getChartData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "monthly_user",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getTopgainerData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "top_gainer",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getTodayTopgainerData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "today_top_gainer",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getTopInvestorData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "top_investor",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getTodayTopInvestorData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "today_top_investor",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getTopMarketorData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "top_marketer",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getTodayTopmarketData() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "today_top_marketer",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function crown() {
  fetch(cron_full);
  fetch(cron_income);
}

export function getContractJoining() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "joiningTransaction",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getContractLevel() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "levelTransaction",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getContractMatrix() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "matrixTransaction",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserLevel(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "userlevelTransaction",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getUserMatrix(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "usermatrixTransaction",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

// export function getUserTotalTxn(user_hex) {
//   return fetch(url, {
//     method: "POST",
//     signal: controller.signal,
//     body: JSON.stringify({
//       action: "usertotalTransaction",
//       hex_user: user_hex,
//     }),
//   })
//     .then((res) => res.json())
//     .catch((e) => console.log(e));
// }
// export function getUserRecentTnx(user_hex) {
//   return fetch(url, {
//     method: "POST",
//     signal: controller.signal,
//     body: JSON.stringify({
//       action: "userTransaction",
//       hex_user: user_hex,
//     }),
//   })
//     .then((res) => res.json())
//     .catch((e) => console.log(e));
// }
export function getUserDownline(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "userDownline",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getMatrixDetails(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_matrix_details",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getSystemTodayEntryStats() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_today_entry_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getSystemTotalEntryStats() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_total_entry_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserTotalEntryStats(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_total_entry_stats",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserTodayEntryStats(user_hex) {
  console.log("User Hex", user_hex);
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_today_entry_stats",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getUserTotalSponcerStats(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_total_sponsor_stats",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserTodaySponcerStats(user_hex) {
  console.log("User Hex", user_hex);
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_today_sponsor_stats",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function getSystemTotalSponcerStats() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_total_sponsor_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getSystemTodaySponcerStats() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_today_sponsor_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getSystemReEntry() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "system_reentry_stats",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function getUserReEntry(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "user_reentry_stats",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function systemTopBar() {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "systemTopBar",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}
export function isAlreadyBuy(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "is_already_buy",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

export function isAlreadyLevelBuy(user_hex) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "is_level_already_buy",
      hex_user: user_hex,
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
}

// export function getUserDirect(user_hex) {
//   return fetch(url, {
//     method: "POST",
//     signal: controller.signal,
//     body: JSON.stringify({
//       action: "userDirect",
//       hex_user: user_hex,
//     }),
//   })
//     .then((res) => res.json())
//     .catch((e) => console.log(e));
// }

export function viewUserWallet(id) {
  return fetch(url, {
    method: "POST",
    signal: controller.signal,
    body: JSON.stringify({
      action: "view",
      user: id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => console.log(e));
}
