import React from "react";

export default function Loader() {
  return (
    <div className="container-fluid site-width loader-container">
      <div
        className="d-flex justify-content-center text-center align-items-center loader"
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}
