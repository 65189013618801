import React, { useEffect, useState } from "react";

import Card from "../components/Card";
import { getUserInfo } from "../helpers/getweb3";
import { useSelector } from "react-redux";
import {
  getuserDirectIncome,
  getuserGeometryIncome,
  getuserStageIncome,
} from "../helpers/api";
import SlotBuy from "../SlotBuy";
export const Reff = () => {
  const [topdata, setData] = React.useState();
  const [totalDirectIncome, setTotalDirectIncome] = useState("");
  const [totalStageIncome, setTotalStageIncome] = useState("");
  const [totalGeometryIncome, setTotalGeomIncome] = useState("");
  const [totalGeometrySpoIncome, setTotalGeomSpoIncome] = useState("");
  const { walletAddress } = useSelector((state) => state.user.value);
  useEffect(() => {
    getUserInfo(walletAddress)
      .then((data) => {
        setData(data);
      })
      .catch((e) => console.log(e));
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      getuserDirectIncome(walletAddress).then((res) => {
        setTotalDirectIncome(
          res.totalIncomeUpline + res.totalIncomedirectReferal
        );
      });
      getuserStageIncome(walletAddress).then((res) => {
        setTotalStageIncome(res.total_amountRecv);
      });
      getuserGeometryIncome(walletAddress).then((res) => {
        setTotalGeomSpoIncome(res.total_sponserGetReward);
        setTotalGeomIncome(res.total_rewardGet);
      });
    }
  }, [walletAddress]);

  // console.log(totalDirectIncome, totalStageIncome,totalGeometryIncome,totalGeometrySpoIncome, "totalref");
  return (
    <>
      {" "}
      <div className="container-Ref-box-top">
        <div className="row">
          <Card
            icon={<i class="fas fa-coins"></i>}
            theme={"primary"}
            title="Total Register Income"
            value={`${totalDirectIncome ? totalDirectIncome : 0} ARB`}
          />
          <Card
            icon={<i class="fas fa-money-check-alt"></i>}
            theme={"success"}
            title="Stage Upgrade Income"
            value={`${totalStageIncome ? totalStageIncome?.toFixed(2) : 0} ARB`}
          />
        </div>
        <div className="row">
          <Card
            icon={<i class="fas fa-money-check-alt"></i>}
            theme={"warning"}
            title="Sponser Income From Geometry"
            value={` ${
              totalGeometrySpoIncome ? totalGeometrySpoIncome : 0
            } ARB`}
          />
          <Card
            icon={<i class="fas fa-dice-d20"></i>}
            theme={"info"}
            title=" Geometry Upgrade Income"
            value={` ${totalGeometryIncome ? totalGeometryIncome : 0} ARB`}
          />
        </div>{" "}
      </div>
      <div className="container-Ref-box-bottom">
        <SlotBuy />
        <div className="row">
          <Card
            icon={<i class="fas fa-coins"></i>}
            theme={"primary"}
            title="Total Register Income"
            value={`${totalDirectIncome ? totalDirectIncome : 0} ARB`}
          />
          <Card
            icon={<i class="fas fa-money-check-alt"></i>}
            theme={"success"}
            title="Stage Upgrade Income"
            value={`${totalStageIncome ? totalStageIncome?.toFixed(2) : 0} ARB`}
          />
        </div>
        <div className="row">
          <Card
            icon={<i class="fas fa-money-check-alt"></i>}
            theme={"warning"}
            title="Sponser Income From Geometry"
            value={` ${
              totalGeometrySpoIncome ? totalGeometrySpoIncome : 0
            } ARB`}
          />
          <Card
            icon={<i class="fas fa-dice-d20"></i>}
            theme={"info"}
            title=" Geometry Upgrade Income"
            value={` ${totalGeometryIncome ? totalGeometryIncome : 0} ARB`}
          />
        </div>{" "}
      </div>
    </>
  );
};

export default Reff;
