import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { connect, useSelector } from "react-redux";
function ReffralLink(props) {
  const {  walletAddress, userApiData } = useSelector(
    (state) => state.user.value
  );
  const [refUrl, setRefUrl] = React.useState("");
  //http://localhost:3000/create
  useEffect(() => {
    setRefUrl(
      userApiData?.member_user_id != null
        ? `https://rwa.wyscale.com/create/${userApiData?.member_user_id}`
        : "Please wait for few minutes, we are generating your referral link."
    );
  }, [walletAddress, userApiData]);
  
  const onClick = () => {
    if (userApiData?.member_user_id != null) {
      let copyText = document.getElementById("reffralcode");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      NotificationManager.info("Refferal Link Copied!", "", 2000);
    } else {
      NotificationManager.error("Please wait for few minutes, we are generating your referral link.");
    }
    // navigator.clipboard.writeText(url);
  };
  
  return (
    <div className="row">
      <div className=" row offset-md-7 col-md-5 px-3">
        <div className="input-group  mb-3 m-3 address">
          <input
            className={ userApiData?.member_user_id != null?"form-control":"form-control fst-italic"} 
            id="reffralcode"
            defaultValue={refUrl}
            readOnly
          />
          <button
            className="input-group-text btn-sm btn btn-copy-gradient"
            onClick={onClick}
          >
            <span className="icon-globe mr-2 h6 mb-0">
              <i class="fas fa-wallet"></i> Copy
            </span>{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReffralLink;
