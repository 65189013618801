import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  geomatrixStatics,
  todayGeomatrixStatics,
} from "../pages/helpers/apiFunctions";
import { Pagination } from "@mui/material";
import Loader from "./components/Loader";
import NoReacord from "./components/NoReacord";
import { getuserGeometryIncome } from "./helpers/api";
import moment from "moment/moment";
export const GeomatricStatic = (props) => {
  const [geoMatrix, setGeoMatrix] = React.useState();
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const { walletAddress } = useSelector((state) => state.user.value);

  const [loading, setLoading] = React.useState(true);
  function getDirectData(p) {
    getuserGeometryIncome(walletAddress, p)
      .then((d) => {
        console.log("Geometry Data", d);
        setGeoMatrix(d?.data);
        setTotal(d.totalData);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getDirectData(page);
  }, [page, walletAddress]);

  const handleChange = (e, p) => {
    getDirectData(p);

    setPage(p);
  };
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Level Txn-->
                  <!-- Level Transaction --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Geometric Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>#</strong>
                      </td>
                      <td>
                        <strong>Geometric Name</strong>
                      </td>
                      <td>
                        <strong>Geometric Symobl</strong>
                      </td>

                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                      <td>
                        <strong>Income Type</strong>
                      </td>
                      <td>
                        <strong>Created at</strong>
                      </td>
                    </tr>
                    {geoMatrix?.map((d, index) => (
                      <GeoCard
                        data={d}
                        index={index}
                        key={index}
                        walletAddress={walletAddress}
                      />
                    ))}
                    {geoMatrix?.length === 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
                <hr color="#cbb42a" />
                <div className="d-sm-flex text-center justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(total / 10)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

function GeoCard(props) {
  let arr = [
    { title: "Pentagonal", icon: "penta" },
    { title: "Hexagonal", icon: "hexa" },
    { title: "Heptagonal", icon: "hepta" },
    { title: "Octagonal", icon: "octa" },
    { title: "Nonagonal", icon: "nona" },
    { title: "Decagonal", icon: "deca" },
  ];

  return (
    <tr className="gray zoom">
      <td>{props.index + 1}</td>
      <td>{arr[props.data.geo - 5].title}</td>
      <td>
        <img
          src={`./images/geom/${arr[props.data.geo - 5].icon}.png`}
          width="50"
          height="50"
        />
      </td>

      <td>
        {props.walletAddress.toLowerCase() == props.data.user.toLowerCase()
          ? props.data.rewardGet/1e18
          : props.data.sponserGetReward/1e18}{" "}
        ARB
      </td>
      <td>
        {props?.walletAddress.toLowerCase() == props.data.user.toLowerCase() ? (
          <span
            className="badge text-bg-success"
            style={{ background: "#ccb107" }}
          >
            Direct{" "}
          </span>
        ) : (
          <span
            className="badge text-bg-success"
            style={{ background: "#508f27" }}
          >
           Upline
          </span>
        )}{" "}
      </td>
      <td> {moment(Number(props.data.block_timestamp) * 1000).fromNow()}</td>
    </tr>
  );
}

export default GeomatricStatic;
