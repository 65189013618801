import React, { useEffect } from "react";
import Loader from "./components/Loader";
import {
  contractTransaction,
  getContractJoining,
  getContractLevel,
  getContractMatrix,
} from "./helpers/apiFunctions";
import NoReacord from "./components/NoReacord";
function ContractTxn() {
  const [allContract, setAllContract] = React.useState();
  const [joiningContract, setJoiningContract] = React.useState();
  const [levelContract, setLevelContract] = React.useState();
  const [matrixContract, setMatrixContract] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    contractTransaction()
      .then((data) => {
        setAllContract(data);
        getContractJoining()
          .then((d2) => {
            console.log("joinng", d2);
            setJoiningContract(d2);
            getContractLevel()
              .then((data1) => {
                setLevelContract(data1);
                getContractMatrix()
                  .then((data2) => {
                    setMatrixContract(data2);
                    setTimeout(() => setLoading(false), 1000);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Joining Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Refferal</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {joiningContract?.map((d, index) => {
                      return (
                        <tr className="gray zoom" key={index}>
                          <td>{d.date?.split(" ")[0]}</td>
                          <td>
                            {d.txId.substr(0, 12)}...{d.txId.substr(-5)}
                          </td>
                          <td>
                            {d.user_wallet?.substr(0, 10) ||
                              "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(
                                0,
                                10
                              )}
                            ...
                            {d.user_wallet?.substr(-3) ||
                              "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(-3)}
                          </td>
                          <td>{d.amount} BTT</td>
                          <td title={d.referral_wallet}>
                            {d.referral_wallet?.substr(0, 10)}
                            ...
                            {d.referral_wallet?.substr(-5)}
                          </td>
                          <td>
                            <a
                              href={`https://tronscan.org/#/transaction/${d.txId}`}
                              style={{ color: "red" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Level Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Level</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {levelContract?.map((d, index) => (
                      <tr className="gray zoom" key={index}>
                        <td>{d.date.split(" ")[0]}</td>
                        <td>
                          {d.txId.substr(0, 12)}...{d.txId.substr(-5)}
                        </td>
                        <td>
                          {d.user_wallet?.substr(0, 10) ||
                            "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(0, 10)}
                          ...
                          {d.user_wallet?.substr(-3) ||
                            "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(-3)}
                        </td>
                        <td>
                          <img
                            src={`/auth/images/zig/${d.level}.png`}
                            width="50"
                            height="50"
                            alt="zigzag level"
                          />
                        </td>
                        <td>{d.amount} BTT </td>
                        <td>
                          <a
                            href={`https://tronscan.org/#/transaction/${d.txId}`}
                            style={{ color: "red" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fas fa-external-link-alt"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Geometric Txn. History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>Matrix</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {matrixContract?.map((d, index) => {
                      let arr = [
                        { title: "Pentagonal", icon: "penta" },
                        { title: "Hexagonal", icon: "hexa" },
                        { title: "Heptagonal", icon: "hepta" },
                        { title: "Octagonal", icon: "octa" },
                        { title: "Nonagonal", icon: "nona" },
                        { title: "Decagonal", icon: "deca" },
                      ];
                      return (
                        <tr className="gray zoom" key={index}>
                          <td>{d.date.split(" ")[0]}</td>
                          <td>
                            {d.txId.substr(0, 12)}...{d.txId.substr(-5)}
                          </td>
                          <td>
                            <img
                              src={`/auth/images/geom/${
                                arr[d.level - 1].icon
                              }.png`}
                              width="50"
                              height="50"
                              alt="geometric level"
                            />
                          </td>
                          <td>{d.amount} BTT</td>
                          <td>
                            <a
                              href={`https://tronscan.org/#/transaction/${d.txId}`}
                              style={{ color: "red" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                    {matrixContract?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Contract Transaction History</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Hash</strong>
                      </td>
                      <td>
                        <strong>From</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>Tronscan</strong>
                      </td>
                    </tr>
                    {allContract?.map((d, index) => (
                      <tr className="gray zoom" key={index}>
                        <td>{d.date?.split(" ")[0]}</td>
                        <td>
                          {d.txId.substr(0, 12)}...{d.txId.substr(-5)}
                        </td>
                        <td>
                          {d.user_wallet?.substr(0, 10) ||
                            "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(0, 10)}
                          ...
                          {d.user_wallet?.substr(-3) ||
                            "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb".substr(-3)}
                        </td>
                        <td>{d.amount} BTT</td>
                        <td>
                          <a
                            href={`https://tronscan.org/#/transaction/${d.txId}`}
                            style={{ color: "red" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fas fa-external-link-alt"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                    {allContract?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ContractTxn;
