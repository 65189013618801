import React from "react";
import img from "./output-onlinegiftools.gif";
import { useEffect } from "react";

const Model = () => {
  useEffect(() => {
    const button = document.querySelector('[data-target="#staticBackdrop1"]');
    if (button) {
      button.click();
    }
  }, []);

  return (
    <div>
      <div data-toggle="modal" data-target="#staticBackdrop1">
        {" "}
      </div>
      <div
        class="modal fade"
        id="staticBackdrop1"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div
              class="modal-header text-center"
              style={{ background: "#171717", border: "1px solid #393737" }}
            >
              <h5 class="modal-title text-white " id="staticBackdropLabel1">
                <img src={img} height={40} /> Big Announcement 🚀
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body text-center text-white font-weight-bolder "
              style={{ background: "#171717" }}
            >
              <h5 className="pt-1">
              Withdrawal fees of 5% has been waived off now i.e. NO WITHDRAWAL FEES
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Model;
