import React, { useEffect } from "react";
import {  useSelector } from "react-redux";
import Loader from "./components/Loader";
import NoReacord from "./components/NoReacord";
import { getuserDirectIncome, getuserclaimIncome } from "./helpers/api";
import { Pagination } from "@mui/material";
import moment from "moment/moment";

const ClaimBonus = () => {

  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const { walletAddress } = useSelector((state) => state.user.value);
  const [downlineData, setDownlineData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  function getDirectData(p) {
    getuserclaimIncome(walletAddress, p)
      .then((d) => {
        
        setDownlineData(d?.data);
        setTotal(d.totalData);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getDirectData(page);
  }, [page, walletAddress]);

  const handleChange = (e, p) => {
    getDirectData(p);

    setPage(p);
  };
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Today zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title"> Claim Bonus Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                    <td>
                        <strong>#</strong>
                      </td>
                     
                      <td>
                        <strong>Transaction ID</strong>
                      </td>
                    
                      <td>
                        <strong>User </strong>
                      </td>
                     
                      {/* <td>
                        <strong>Total Amount (after Deduction)</strong>
                      </td> */}
                      <td>
                        <strong>Received Amount (after Deduction)</strong>
                      </td>
                      <td>
                        <strong>Income Type</strong>
                      </td>
                      <td>
                        <strong>Created at</strong>
                      </td>
                    </tr>
                    {downlineData?.map((d, index) => {
                      return (
                        <tr className="gray zoom" key={index + "direct"}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="text-success">
                              {" "}
                              <a
                                target="_blank"
                                href={`https://arbiscan.io/tx/${d?.transaction_id}`}
                              >
                                <i class="fas fa-external-link-alt"></i>
                              </a>
                            </span>
                            <span className="p-1">
                              {(d?.transaction_id).slice(0, 8) +
                                "..." +
                                (d?.transaction_id).slice(-8)}
                            </span>
                          </td>
                        
                          <td>
                            {(d?.user).slice(0, 6) +
                              "..." +
                              (d?.user).slice(-6)}
                          </td>

                          <td>{d.amountclaim/1e18} ARB</td>
                        <td> <span className="badge text-bg-success" style={{background:"#f64e60"}}>Claim Bonus</span>  </td>
                          <td>
                            {" "}
                            {moment(Number(d.block_timestamp) * 1000).fromNow()}
                          </td>
                          <td style={{ color: "green" }}>{d.referral}</td>
                        </tr>
                      );
                    })}
                    {downlineData?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
                <hr color="#cbb42a" />
                <div className="d-sm-flex text-center justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(total / 10)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </>
    );
};





export default ClaimBonus
