import React from "react";
// import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
import { Link, useLocation } from "react-router-dom";
export const Menu = (props) => {
  const location = useLocation();
  const active = location.pathname;
  return (
    <div className="sidebar" id="sidebar">
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          <li className="dropdown">
            <a href="#" className="background-success">
              <i className="fas fa-home mr-1"></i> Main
            </a>
            <ul>
              <li className={active === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <i className="fas fa-layer-group"></i> User Dashboard
                </Link>
              </li>
            </ul>
          </li>

          <li className="dropdown">
            <a href="#">
              <i className="fas fa-sitemap"></i> Structure
            </a>
            <ul>
              <li className={active === "/direct" ? "active" : ""}>
                <Link to="/direct">
                  <i className="fas fa-user-friends"></i> Direct
                </Link>
              </li>
              <li className={active === "/downline" ? "active" : ""}>
                <Link to="/downline">
                  <i className="fas fa-users "></i> Downline
                </Link>
              </li>

              <li className={active === "/geostructure" ? "active" : ""}>
                <Link to="/geostructure">
                  <i className="fas fa-dice-d20"></i> Geometric Structure
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#">
              <i className="icon-layers mr-1"></i> Stats
            </a>
            <ul>
              <li className={active === "/userentrybonus" ? "active" : ""}>
                <Link to="/userentrybonus">
                  <i className="fas fa-chart-line"></i> User Entry Bonus Stats
                </Link>
              </li>

              <li className={active === "/zigzagstats" ? "active" : ""}>
                <Link to="/zigzagstats">
                  <i className="fas fa-signal"></i> User Stage Stats
                </Link>
              </li>
              <li className={active === "/geostats" ? "active" : ""}>
                <Link to="/geostats">
                  <i className="fas fa-dice-d20"></i>User Geometric Stats
                </Link>
              </li>
              <li className={active === "/claimBonus" ? "active" : ""}>
                <Link to="/claimBonus">
                  <i className="fas fa-dice-d20"></i> Claim Bonus Stats
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
