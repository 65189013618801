import React from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { buyToken, sellToken } from "./helpers/helperfunctions";

export default function BuyNSell(props) {
  const [isBuyActive, setBuyActive] = React.useState(1);
  const [atPrice, setPrice] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  return (
    <>
      <ul
        className="nav nav-tabs"
        id="myTab"
        role="tablist"
        style={{
          display: "flex",
          flex: 1,
          boxShadow: "5px 5px 18px 9px #e5e5e5",
        }}
      >
        <li
          className="nav-item"
          role="presentation"
          style={{ flexGrow: 1 }}
          onClick={() => setBuyActive(1)}
        >
          <div
            className={`nav-link text-center ${isBuyActive ? "active show" : ""}`}
            id="buy-tab"
            type="button"
            role="tab"
          >
            BUY
          </div>
        </li>
        <li
          className="nav-item"
          role="presentation "
          style={{ flexGrow: 1 }}
          onClick={() => setBuyActive(0)}
        >
          <div
            className={`nav-link text-center ${
              isBuyActive ? "" : "active show"
            }`}
            id="sell-tab"
            type="button"
            role="tab"
          >
            SELL
          </div>
        </li>
      </ul>

      <div
        className="tab-content"
        style={{
          height: "300px",
          boxShadow: "1px 1px 5px 2px #e4e4e4",
          backgroundColor: "#fff",
        }}
      >
        <div
          className={`tab-pane fade ${
            isBuyActive ? "active show" : ""
          } p-3 py-5`}
          role="tabpanel"
        >
          <div className="bal bal-info mb-3">
            <span className="pl-2 pr-1 font-weight-bold">Balance </span>
            <span className="pr-2">:</span>
            <span>0</span>
            <span className="px-1">BTT</span>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                At Price
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Price"
              aria-label="Price"
              value={atPrice}
              onChange={(e) => {
                e.target.value.replace(/\D/, "");
                setPrice(e.target.value.replace(/\D/, ""));
                setTotal(amount * e.target.value.replace(/\D/, ""));
              }}
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Amount
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Amount"
              aria-label="Amount"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value.replace(/\D/, ""));
                setTotal(atPrice * e.target.value.replace(/\D/, ""));
              }}
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Total
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Total"
              aria-label="Total"
              value={total}
              readOnly
              aria-describedby="basic-addon1"
            />
          </div>
          <button
            type="button"
            className="btn btn-block btn-outline-success"
            onClick={() => {
              if(amount)
              buyToken(amount);
              else 
              NotificationManager.error("Enter valid Amount.")
            }}
          >
            BUY
          </button>
        </div>
        <div
          className={`tab-pane fade ${
            isBuyActive ? "" : "active show"
          } p-3 py-5`}
          role="tabpanel"
        >
          <div className="input-group my-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                At Price
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Price"
              aria-label="Price"
              value={atPrice}
              onChange={(e) => {
                setPrice(e.target.value.replace(/\D/, ""));
                setTotal(amount * e.target.value.replace(/\D/, ""));
              }}
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Amount
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Amount"
              aria-label="Amount"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value.replace(/\D/, ""));
                setTotal(atPrice * e.target.value.replace(/\D/, ""));
              }}
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Total
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Total"
              aria-label="Total"
              value={total}
              readOnly
              aria-describedby="basic-addon1"
            />
          </div>
          <button
            type="button"
            className="btn btn-block btn-outline-danger"
            onClick={() => {
              if(amount)
              sellToken(amount);
              else 
              NotificationManager.error("Enter valid Amount")
            }}
          >
            SELL
          </button>
        </div>
      </div>
    </>
  );
}
