import React, { useEffect } from "react";
import {
  SystemGeomatrixStatics,
  todaySystemGeomatrixStatics,
} from "../pages/helpers/apiFunctions";
import Loader from "./components/Loader";
import NoReacord from './components/NoReacord';
export const SystemGeomatricStatic = () => {
  const [geoMatrix, setGeoMatrix] = React.useState();
  const [todayGeoMatrix, setTodayGeoMatrix] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    todaySystemGeomatrixStatics()
      .then((data) => {
        console.log("Today System Geo", data);
        setTodayGeoMatrix(data);
        SystemGeomatrixStatics()
          .then((d) => {
            console.log("all System Geo", d);
            setGeoMatrix(d);
            setTimeout(() => setLoading(false), 2000);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- New Joining Txn--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Today System Geometric Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Geometric Name</strong>
                      </td>
                      <td>
                        <strong>Geometric Symobl</strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Count</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>

                    {todayGeoMatrix?.length &&
                      todayGeoMatrix?.map((d, index) => (
                        <GeoCard {...d} key={index} />
                      ))}
                      {todayGeoMatrix?.length === 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Level Txn-->
                  <!-- Level Transaction --> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Total System Geometric Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Geometric Name</strong>
                      </td>
                      <td>
                        <strong>Geometric Symobl</strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Count</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>
                    {geoMatrix?.map((d, index) => (
                      <GeoCard {...d} key={index} />
                    ))}
                    {geoMatrix?.length === 0 ? (
                      <NoReacord colspan={6} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

function GeoCard(props) {
  let arr = [
    { title: "Pentagonal", icon: "penta" },
    { title: "Hexagonal", icon: "hexa" },
    { title: "Heptagonal", icon: "hepta" },
    { title: "Octagonal", icon: "octa" },
    { title: "Nonagonal", icon: "nona" },
    { title: "Decagonal", icon: "deca" },
  ];

  return (
    <tr className="gray zoom">
      <td>{arr[props.level - 1].title}</td>
      <td>
        <img
          src={`./images/geom/${arr[props.level - 1].icon}.png`}
          width="50"
          height="50"
        />
      </td>
      <td>{props.total_entry}</td>
      <td>{props.total_invest} BTT</td>
      <td>{props.total_execution} </td>
      <td>{props.total_income} BTT </td>
    </tr>
  );
}

export default SystemGeomatricStatic;
