import React, { useEffect } from "react";
import BuyNSell from "./BuyNSell";
import Chart from "./components/Chart";
import ExchangeTnx from "./ExchangeTnx";
import { getTokenPrice } from "./helpers/helperfunctions";
import UserExchangeTnx from "./userExchangeTnx";
export default function Exchange(props) {
  useEffect(()=>{
    setTimeout(getTokenPrice,800);
  })
  return (
    <div className="row col-12 col-lg-12 mt-3">
      <div className="col-12 col-md-8 col-lg-8">
        <Chart />
      </div>
      <div className="col-12 col-md-4 col-lg-4 px-2">
        <BuyNSell />
      </div>
      <div className="col-12 col-lg-12 mt-3">
        <UserExchangeTnx />
      </div>
      <div className="col-12 col-lg-12 mt-3">
        <ExchangeTnx />
      </div>
    </div>
  );
}
