import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getSystemTotalEntryStats,
  getSystemTodayEntryStats,
} from "./helpers/apiFunctions";
import Loader from "./components/Loader";
import NoReacord from './components/NoReacord';
const SystemEntryBonus = (props) => {
  const [zigzag, setZigzag] = React.useState();
  const [todayZigzag, setTodayZigzag] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getSystemTodayEntryStats()
      .then((d) => {
        console.log(d);
        setTodayZigzag(d);
        getSystemTotalEntryStats(props.hex_user)
          .then((data) => {
            setZigzag(data);
            setTimeout(() => setLoading(false), 2000);
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Today zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title"> Entry Bonus Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Level </strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Amount</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>
                    {todayZigzag?.map((d, index) => (
                      <ZigZagCard {...d} key={index} />
                    ))}
                     {todayZigzag?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- all zigzag stats--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Total Entry Bonus Stats</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Level</strong>
                      </td>
                      <td>
                        <strong>Entry Count</strong>
                      </td>
                      <td>
                        <strong>Investment Amount</strong>
                      </td>
                      <td>
                        <strong>Execution Count</strong>
                      </td>
                      <td>
                        <strong>Executed Amount</strong>
                      </td>
                    </tr>
                    {zigzag?.map((d, index) => (
                      <ZigZagCard {...d} key={index} />
                    ))}
                     {zigzag?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

function ZigZagCard(props) {
  return (
    <tr className="gray zoom">
      <td>Level {props.level}</td>
      <td>{props.total_entry} </td>
      <td>{props.total_invest} ARB</td>
      <td>{props.total_execution} </td>
      <td>{props.total_income} ARB </td>
    </tr>
  );
}



export default   SystemEntryBonus
// export default ZigzagStatic;
