import React, { Component } from "react";
import HeaderMenu from "./HeaderMenu";
// import { connect } from 'react-redux'

export default class Header extends Component {
  render() {
    return (
      <div id="header-fix" className="header fixed-top">
        <div className="site-width">
          <nav className="navbar navbar-expand-lg  p-0">
            <div className="navbar-header  h-100 h4 mb-0 align-self-center logo-bar text-left">
              <a href="" className="horizontal-logo text-left">
                  <img src="./wy.png" alt=""  height={35}/>
                <span className="h5 font-weight-bold align-self-center mb-0 ml-auto" style={{color:"#cbb42a"}}>
                  WYscale
                </span>
              </a>
            </div>
            <div className="navbar-header h4 mb-0 text-center h-100 collapse-menu-bar d-block d-sm-none d-md-none d-lg-none">
              <a href="#"
                className="sidebarCollapse"
                onClick={() => {
                  window.jQuery(".sidebar").toggleClass("active");
                }}
              >
                <i className="fas fa-bars" style={{color:"#292b3a"}}></i>
              </a>
            </div>
            {/* {props.children} */}
            <HeaderMenu />
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(Header)
