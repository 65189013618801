import React from "react";

export default function NoReacord(props) {
  return (
    <tr>
      <td colSpan={props.colspan}>
        <div className="text-center">{props.message}</div>
      </td>
    </tr>
  );
}
