import React from "react";

export default function Nona(props) {
  const [user, setUser] = React.useState("");
  return (
    <svg
      width="500"
      height="500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <g id="nona">
          <g id="svg_10">
            <polygon
              id="svg_11"
              points="261,72.5999984741211 145.39999389648438,114.69999694824219 83.9000015258789,221.1999969482422 105.30000305175781,342.29998779296875 199.5,421.3999938964844 322.5,421.3999938964844 416.70001220703125,342.29998779296875 438.1000061035156,221.1999969482422 376.6000061035156,114.69999694824219 "
              className="st2"
              fill="#231F20"
            />
            <g id="svg_12">
              <g id="svg_13">
                <g id="svg_14">
                  <path
                    id="svg_15"
                    d="m261.4,149.2c-27.3,0 -49.6,22.3 -49.6,49.6s22.3,49.6 49.6,49.6s49.6,-22.3 49.6,-49.6s-22.4,-49.6 -49.6,-49.6z"
                    className="st3"
                    fill="#ffffff"
                  />
                </g>
              </g>
              <g id="svg_16">
                <g id="svg_17">
                  <path
                    id="svg_18"
                    d="m346.4,287.9c-1.3,-3.2 -3,-6.3 -5,-9.1c-10,-14.7 -25.3,-24.5 -42.6,-26.8c-2.2,-0.2 -4.5,0.2 -6.3,1.5c-9.1,6.7 -19.9,10.2 -31.2,10.2c-11.3,0 -22.1,-3.5 -31.2,-10.2c-1.7,-1.3 -4.1,-1.9 -6.3,-1.5c-17.3,2.4 -32.9,12.1 -42.6,26.8c-1.9,2.8 -3.7,6.1 -5,9.1c-0.6,1.3 -0.4,2.8 0.2,4.1c1.7,3 3.9,6.1 5.8,8.7c3,4.1 6.3,7.8 10,11.3c3,3 6.5,5.8 10,8.7c17.1,12.8 37.7,19.5 58.9,19.5s41.8,-6.7 58.9,-19.5c3.5,-2.6 6.9,-5.6 10,-8.7c3.5,-3.5 6.9,-7.1 10,-11.3c2.2,-2.8 4.1,-5.6 5.8,-8.7c1.1,-1.3 1.3,-2.8 0.6,-4.1z"
                    className="st3"
                    fill="#ffffff"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

        {props.length >= 9 ? (
          <g id="svg_50">
            <text
              fill="#000000"
              x="297.2647"
              y="469.65292"
              id="svg_40"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              9 .{" "}
              {props.users.length >= 9
                ? props.users[8]?.slice(0, 4) +
                  "..." +
                  props.users[8]?.slice(-4)
                : null}
            </text>
            <g id="svg_43" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="323.20501"
                cy="436.7853"
                id="svg_41"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_42"
                d="m326.94465,435.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 8 ? (
          <g id="svg_51">
            <text
              fill="#000000"
              x="407.2647"
              y="378.65292"
              id="svg_35"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              8 .{" "}
              {props.users.length >= 8
                ? props.users[7]?.slice(0, 4) +
                  "..." +
                  props.users[7]?.slice(-4)
                : null}
            </text>
            <g id="svg_38" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="433.20501"
                cy="345.7853"
                id="svg_36"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_37"
                d="m436.94465,344.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 7 ? (
          <g id="svg_52">
            <text
              fill="#000000"
              x="434.2647"
              y="250.65292"
              id="svg_30"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              7 .{" "}
              {props.users.length >= 7
                ? props.users[6]?.slice(0, 4) +
                  "..." +
                  props.users[6]?.slice(-4)
                : null}
            </text>
            <g id="svg_33" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="455.20501"
                cy="217.7853"
                id="svg_31"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_32"
                d="m458.94465,216.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {/* #6 */}
        {props.length >= 6 ? (
          <g>
            <text
              fill="#000000"
              x="360.2647"
              y="89.65292"
              id="svg_25"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              6 .{" "}
              {props.users.length >= 6
                ? props.users[5]?.slice(0, 4) +
                  "..." +
                  props.users[5]?.slice(-4)
                : null}
            </text>
            <g id="svg_28" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="391.20501"
                cy="109.7853"
                id="svg_26"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_27"
                d="m394.94465,108.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 5 ? (
          <g id="svg_54">
            <text
              fill="#000000"
              x="230.2647"
              y="36.65292"
              id="svg_20"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              5 .{" "}
              {props.users.length >= 5
                ? props.users[4]?.slice(0, 4) +
                  "..." +
                  props.users[4]?.slice(-4)
                : null}
            </text>
            <g id="svg_23" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="260.20501"
                cy="57.7853"
                id="svg_21"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_22"
                d="m263.94465,56.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 4 ? (
          <g>
            <text
              fill="#000000"
              x="105.2647"
              y="90.65292"
              id="svg_6"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              4 .{" "}
              {props.users.length >= 4
                ? props.users[3]?.slice(0, 4) +
                  "..." +
                  props.users[3]?.slice(-4)
                : null}
            </text>
            <g id="svg_9" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="129.20501"
                cy="110.7853"
                id="svg_7"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_8"
                d="m132.94465,109.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 3 ? (
          <g id="svg_59">
            <text
              fill="#000000"
              x="32.2647"
              y="256.65292"
              id="svg_1"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              3 .{" "}
              {props.users.length >= 3
                ? props.users[2]?.slice(0, 4) +
                  "..." +
                  props.users[2]?.slice(-4)
                : null}
            </text>
            <g id="svg_4" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="65.20501"
                cy="223.7853"
                id="svg_2"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_3"
                d="m68.94465,222.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 2 ? (
          <g id="svg_60">
            <text
              fill="#000000"
              x="69.2647"
              y="385.65292"
              id="svg_55"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              2 .{" "}
              {props.users.length >= 2
                ? props.users[1]?.slice(0, 4) +
                  "..." +
                  props.users[1]?.slice(-4)
                : null}
            </text>
            <g id="svg_58" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="95.20501"
                cy="352.7853"
                id="svg_56"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_57"
                d="m98.94465,351.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 1 ? (
          <g id="svg_49" stroke="null">
            <text
              fill="#000000"
              x="170.2647"
              y="469.65292"
              id="svg_45"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              1 .{" "}
              {props.users.length >= 1
                ? props.users[0]?.slice(0, 4) +
                  "..." +
                  props.users[0]?.slice(-4)
                : null}
            </text>
            <g id="svg_48" stroke="null">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="196.20501"
                cy="436.7853"
                id="svg_46"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_47"
                d="m199.94465,435.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
      </g>
    </svg>
  );
}
