import NotificationManager from "react-notifications/lib/NotificationManager";
import { logout } from "../redux/actions/authActions";
import { store } from "../redux/store";

// const CONTRACT_ID = "TB8a1AS59yQxRfBFzeRco4h1epCpTSARrd"; //testing
const CONTRACT_ID = "TZ8tb5Ggikqyp133TJQcMGUrdHraFJC9H4"; //Production
const CONTRACT_ID_EXCHANGE = "TFeaGwHWTN3nFvgR1iP2pxtLsvz3ZMATnU"; //New Testing Contract
const CONTRACT_ID_MTR = "TRY8FFg3grf9T73W1MUpByTQWkjrgVECib";
var token_price = 0;
let minBuyAmt, maxBuyAmt, minSellAmt, maxSellAmt, userBalanceTRX, userBalMTR;
export async function getMINMAXSELLNBUY() {}
export function getUserMTRBalance(address) {
  if (window.tronWeb) {
    window.tronWeb
      .contract()
      .at(CONTRACT_ID_MTR)
      .then((contract) => {
        contract
          .balanceOf(address)
          .call()
          .then((bal) => {
            console.log(bal);
            return bal;
          })
          .catch((e) => {
            console.log(e);
          });
      });
  }
}
export function getUserTRXBalance() {}
export async function getTokenPrice() {
  console.log("method called");
  if (window.tronWeb) {
    window.tronWeb
      ?.contract()
      .at(CONTRACT_ID_EXCHANGE)
      .then((contract) => {
        console.log(contract);
        window.exchange = contract;
        contract
          .token_price()
          .call()
          .then((res) => {
            token_price = Number(res._hex) / 100;
            console.log("res", res, token_price);
            contract
              .MAXIMUM_BUY()
              .call()
              .then((d) => {
                maxBuyAmt = Number(d._hex);
                console.log("MaxBuy", Number(d._hex));
              })
              .catch((e) => console.log(e));
            contract
              .MAXIMUM_SALE()
              .call()
              .then((d) => {
                maxSellAmt = Number(d._hex);
                console.log("MaxSell", Number(d._hex));
              })
              .catch((e) => console.log(e));
            contract
              .MINIMUM_BUY()
              .call()
              .then((d) => {
                minBuyAmt = Number(d._hex);
                console.log("MinBuy", Number(d._hex));
              })
              .catch((e) => console.log(e));
            contract
              .MINIMUM_SALE()
              .call()
              .then((d) => {
                minBuyAmt = Number(d._hex);
                console.log("MinSell", Number(d._hex));
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    console.log("tronWeb not mila");
  }
}
export async function gettronWebContract() {
  console.log("get contract call ");
  if (window.tronWeb)
    return window.tronWeb
      ?.contract()
      .at(CONTRACT_ID)
      .then((contract) => {
        console.log("contract parent", contract);
        window.contract = contract;
        return contract;
      })
      .catch((e) => {
        console.log("Error  somth", e);
        // NotificationManager.error(e, "ERROR", 2000);
        store.dispatch(logout(() => (document.location.href = "/auth/login")));
        if (typeof e === "string") {
          NotificationManager.error(e, "ERROR", 2000);
        }
      });
}

export function checkUserTokenLevel1(wallet_addr) {
  console.log("Token wallet check", window.contract);
  if (window.tronWeb)
    return gettronWebContract()
      .then((contract) => {
        console.log("contract", contract);
        return contract
          ?.usersActiveX3Levels(wallet_addr, 1)
          .call()
          .then((d) => {
            console.log("2000", d);
            return d;
          })
          .catch((e) => e);
      })
      .catch((E) => console.log(E));
}
// }
export function checkUserTokenLevel2(wallet_addr) {
  // if (window.tronWeb) {
  console.log("Token wallet check");
  if (window.tronWeb)
    return gettronWebContract()
      ?.then((contract) => {
        return contract
          ?.usersActiveX6Levels(wallet_addr, 1)
          .call()
          .then((d) => {
            console.log("5000", d);
            return d;
          })
          .catch((e) => e);
      })
      .catch((e) => {
        console.log(e);
      });
}
// }

export function buyALevel(matrix, level, amount) {
  if (window.tronWeb)
    return gettronWebContract()
      .then((contract) => {
        return contract
          .buyNewLevel(matrix, level)
          .send({
            feeLimit: 100000000,
            tokenId: 1002000, //production
            // tokenId: 1000695, //testing
            tokenValue: window.tronWeb.toSun(amount),
          })
          .then((res) => {
            setTimeout(() => window.location.reload(), 1000);
            return res;
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(e, "Error", 3000);
          });
      })
      .catch((e) => console.log(e));
}

export function buyToken(amount) {
  console.log();
  if (window.tronWeb) {
    window.tronWeb
      ?.contract()
      .at(CONTRACT_ID_EXCHANGE)
      .then((contract) => {
        console.log(contract);
        contract
          .buyToken(amount)
          .send({
            tokenId: 1000695,
            tokenValue: window.tronWeb.toSun(
              Number((amount * token_price) / 100) + Number(amount)
            ),
            feeLimit: 500000000,
          })
          .then((res) => {
            console.log("Res BuyToken", res);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    NotificationManager.error("Tronweb Not Found!");
  }
}

export function sellToken(amount) {
  if (window.tronWeb) {
    window.tronWeb
      ?.contract()
      .at(CONTRACT_ID_MTR)
      .then((contract) => {
        console.log("MTR Contract", contract);
        contract
          .approve(CONTRACT_ID_EXCHANGE, 10000000000)
          .send()
          .then((res) => {
            console.log(res);
            window.tronWeb
              .contract()
              .at(CONTRACT_ID_EXCHANGE)
              .then((contract) => {
                contract
                  .sellToken(amount)
                  .send({
                    tokenId: 1000695,
                    tokenValue: window.tronWeb.toSun(
                      Number((amount * token_price) / 100) + Number(amount)
                    ),
                    feeLimit: 500000000,
                  })
                  .then((res) => {
                    console.log("Res sellToken", res);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
        // contract
        //   .sellToken(amount)
        //   .send({
        //     tokenId: 1000695,
        //     tokenValue: window.tronWeb.toSun(110),
        //     feeLimit: 500000000,
        //   })
        //   .then((res) => {
        //     console.log("Res sellToken", res);
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    NotificationManager.error("Tronweb Not Found!");
  }
}

export function chartInit(data) {
  let month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var primarycolor = getComputedStyle(document.body).getPropertyValue(
    "--primarycolor"
  );
  var bordercolor = getComputedStyle(document.body).getPropertyValue(
    "--bordercolor"
  );
  var bodycolor = getComputedStyle(document.body).getPropertyValue(
    "--bodycolor"
  );
  var chartjs_multiaxis_bar = document.getElementById("chartjs-account-chart");
  if (chartjs_multiaxis_bar) {
    var barmultiaxisChartData = {
      labels: data?.map((d) => month[d.month - 1]).reverse(),
      datasets: [
        {
          label: "Investment",
          backgroundColor: [
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
            primarycolor,
          ],
          yAxisID: "y-axis-1",
          data: data.map((d) => d.total_user).reverse(),
        },
      ],
    };
    var ctx = document.getElementById("chartjs-account-chart").getContext("2d");

    window.myBar = new window.Chart(ctx, {
      type: "bar",
      data: barmultiaxisChartData,
      options: {
        responsive: true,
        legend: {
          display: false,
          position: "top",
          labels: {
            fontColor: bodycolor,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              maxBarThickness: 10,
              gridLines: {
                display: true,
                color: bordercolor,
                zeroLineColor: bordercolor,
              },
              ticks: {
                fontColor: bodycolor,
              },
            },
          ],
          yAxes: [
            {
              type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: false,
              position: "left",
              id: "y-axis-2",
              gridLines: {
                drawOnChartArea: false,
                color: bordercolor,
                zeroLineColor: bordercolor,
              },
              ticks: {
                fontColor: bodycolor,
              },
            },
            {
              display: true,
              gridLines: {
                display: true,
                color: bordercolor,
                zeroLineColor: bordercolor,
              },
              ticks: {
                fontColor: bodycolor,
              },
            },
          ],
        },
      },
    });
  }
}

// export function created() {
//   window.interval = setInterval(async function () {
//     // console.log("Contract create")
//     //   if(window.tronWeb && !window.contract){
//     //       window.tronWeb.contract().at(CONTRACT_ID).then(function(c){
//     //           window.contract = c;
//     //       });
//     //       // window.tronWeb.contract().at(window.TOKEN_CONTRACT_ADDRESS).then(function(cc){
//     //       //     window.tokencontract = cc;
//     //       // });
//     //   }
//     //   window.tronWeb.trx.getUnconfirmedBalance().then(function(r){
//     //           window.trx_balance = (r/1e6).toFixed(0);
//     //       });
//   }, 1000);
// }
