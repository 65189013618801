import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Deca from "./components/Deca";
import Hepta from "./components/Hepta";
import Hexa from "./components/Hexa";
import Loader from "./components/Loader";
import Nona from "./components/Nona";
import Octa from "./components/Octa";
import Penta from "./components/Penta";
import { getMatrixDetails } from "./helpers/apiFunctions";
import { useLocation } from "react-router-dom";
import { getGeometryStructure } from "./helpers/getweb3";
function GeomatricStructure(props) {
  const { userInfo, geoLength, walletAddress } = useSelector(
    (state) => state.user.value
  );
  const { inWhichGeo } = userInfo;
  const [active, setActive] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [strctureData, setStructure] = React.useState([]);

  useEffect(() => {
    getGeometryStructure(walletAddress, active).then((res) => {
      setTimeout(() => setLoading(false), 3000);
      if (res.length > 0) {
        setStructure(res);
      }
    });
  }, [active, walletAddress]);
  if (loading) return <Loader />;
  else
    return (
      <div className="col-12 col-lg-12 mt-3">
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="btn-group btn-group-toggle flex-wrap"
            data-toggle="buttons"
          >
            <label
              className={`btn  ${
                inWhichGeo >= 5 ? "active btn-success" : "btn-secondary"
              }`}
            >
              <input
                type="radio"
                name="options"
                id="option1"
                readOnly
                autoComplete="off"
                checked={inWhichGeo >= 5}
                onClick={() => inWhichGeo >= 5? setActive(5) :null}
              />
              Pentagon
            </label>
            <label
              className={`btn  ${
                inWhichGeo >= 6 ? "active btn-success" : "btn-secondary"
              }`}
            >
              <input
                type="radio"
                name="options"
                id="option2"
                readOnly
                autoComplete="off"
                checked={inWhichGeo >= 6}
                onClick={() => inWhichGeo >= 6? setActive(6):null}
              />
              Hexagon
            </label>
            <label
              className={`btn  ${
                inWhichGeo >= 7 ? "active btn-success" : "btn-secondary"
              }`}
            
            >
              <input
                type="radio"
                name="options"
                id="option3"
                readOnly
                autoComplete="off"
                checked={inWhichGeo >= 7}
                onClick={() =>inWhichGeo >= 7 ?setActive(7):null}
              />
              Heptagon
            </label>
            <label
            className={`btn  ${
                inWhichGeo >= 8 ? "active btn-success" : "btn-secondary"
              }`}
            >
              <input
                type="radio"
                name="options"
                readOnly
                id="option3"
                autoComplete="off"
                checked={inWhichGeo >= 9}
                onClick={() =>inWhichGeo >= 8? setActive(8):null}
              />
              Octagon
            </label>
            <label
             className={`btn  ${
                inWhichGeo >= 9 ? "active btn-success" : "btn-secondary"
              }`}
            >
              <input
                type="radio"
                name="options"
                id="option3"
                readOnly
                autoComplete="off"
                checked={inWhichGeo >= 9}
                onClick={() =>inWhichGeo >= 9? setActive(9):null}
              />
              Nonagon
            </label>
            <label
              className={`btn  ${
                inWhichGeo >= 10 ? "active btn-success" : "btn-secondary"
              }`}
            >
              <input
                type="radio"
                name="options"
                readOnly
                id="option3"
                autoComplete="off"
                checked={inWhichGeo >= 10}
                onClick={() => inWhichGeo >= 10? setActive(10):null}
              />
              Decagon
            </label>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center pt-3">
          {/* <Penta/> */}
          {active == 5 && (
            <Penta
              length={inWhichGeo <= 5 ? geoLength : 5}
              users={active == 5 ? strctureData : []}
            />
          )}
          {active == 6 && (
            <Hexa
              length={inWhichGeo <= 6 ? geoLength : 6}
              users={active == 6 ? strctureData : []}
            />
          )}
          {active == 7 && (
            <Hepta
              length={inWhichGeo <= 7 ? geoLength : 7}
              users={active == 7 ? strctureData : []}
            />
          )}
          {active == 8 && (
            <Octa
              length={inWhichGeo <= 8 ? geoLength : 8}
              users={active == 8 ? strctureData : []}
            />
          )}
          {active == 9 && (
            <Nona
              length={inWhichGeo <= 9 ? geoLength : 9}
              users={active == 9 ? strctureData : []}
            />
          )}
          {active == 10 && (
            <Deca
              length={inWhichGeo <= 10 ? geoLength : 10}
              users={active == 10 ? strctureData : []}
            />
          )}
        </div>
      </div>
    );
}

export default GeomatricStructure;
