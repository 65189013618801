import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  addressToId,
  getBalance,
  idToAddress,
  isUserExists,
  registerExt,
  startNow,
} from "./helpers/getweb3";
import { setUserExist, setWalletAddress } from "./helpers/redux/dataSlice";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import { getuserRandomIdDetails } from "./helpers/api";
const Register = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const route = useLocation();
  const { walletAddress } = useSelector((state) => state.user.value);
  const [refcode, setRefCode] = React.useState(id);
  const [wallet, setlocalwallet] = useState("");

  const [isRegistering, setIsRegistering] = useState(false);

  function register() {
    // Check if registration is already in progress
    if (isRegistering) {
      return;
    }

    // Set the flag to indicate that registration is starting
    setIsRegistering(true);

    startNow()
      .then((res) => {
        const address = res.userAddress;
        setlocalwallet(address);
        localStorage.setItem("wallet_local", address);
        isUserExists(address).then((res) => {
          if (res) {
            toast.error("User already exists");
          } else {
            dispatch(setWalletAddress({ walletAddress: address }));

            if (!refcode) {
              NotificationManager.error("Enter Sponsor ID");
              setIsRegistering(false);
            } else {
              console.log(refcode,"refcode")
              getuserRandomIdDetails(refcode)
                .then((res) => {
                 console.log(res[0]?.userID,"respo")

                  idToAddress(res[0]?.userID).then((res) => {
                    addressToId(res).then((resId) => {
                      if (!res.includes("0x0000")) {
                        getBalance(walletAddress).then((res1) => {
                          if (res1 > 0) {
                            registerExt(
                              walletAddress,
                              resId,
                              5,
                              setRefresh,
                              setLoading,
                              history
                            ).finally(() => {
                              // Reset the flag when the registration is complete
                              setIsRegistering(false);
                            });
                          } else {
                            NotificationManager.error(
                              "Insufficient Wallet Balance!"
                            );
                            // Reset the flag when the registration is complete
                            setIsRegistering(false);
                          }
                        });
                      } else {
                        NotificationManager.error("Invalid Sponsor ID");
                        // Reset the flag when the registration is complete
                        setIsRegistering(false);
                      }
                    });
                  });
                })
                .catch((err) => {
                  console.log(err, "error Api");
                  setIsRegistering(false);
                  NotificationManager.error("Invalid Sponsor ID");
                });
            }
          }
        });
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error getting wallet address:", error);
        // Reset the flag in case of an error
        setIsRegistering(false);
      });
  }

  // ... rest of your component

  useEffect(() => {
    if (walletAddress) {
      isUserExists(walletAddress).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
    }

    if (route.search) {
      const code =
        route?.search.indexOf("?") > -1 ? route?.search?.split("?")[1] : 0;

      if (code) setRefCode(code);
    }
  }, [refresh, route, walletAddress]);

  return (
    <div
      id="main-container"
      className="default"
      style={{
        backgroundImage: "url(/images/wallet/banner.png)",
        width: "100vw",
        backgroundSize: "cover",
      }}
    >
      {/* <!-- START: Main Content--> */}
      <div className="container">
        <div className="row vh-100 justify-content-between align-items-center">
          <div className="col-12">
            <div className="row row-eq-height lockscreen  mt-5 mb-5">
              <div className=" d-flex lock-image col-12 col-sm-5 justify-content-center align-items-center">
                <Link to="/">
                  <img src="/t Text.svg" height="50" width="100%" />
                </Link>
              </div>

              <div className="login-form col-12 col-sm-7">
                <div className="form-group mb-3">
                  <label
                    htmlFor="invationcode"
                    style={{ fontSize: "16px", color: "#aed249b3" }}
                  >
                    Wallet Address
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Wallet Address"
                    value={wallet ? wallet : walletAddress}
                  />
                </div>
                <div
                  className="form-group mb-3"
                  style={{ fontSize: "16px", color: "#aed249b3" }}
                >
                  <label htmlFor="invationcode">Enter Invitation Code</label>
                  <input
                    className="form-control"
                    type="text"
                    id="invationcode"
                    required="required"
                    placeholder="Invitation Code"
                    value={refcode}
                    onChange={(e) => setRefCode(e.target.value)}
                  />
                </div>

                <div className="form-group mb-0 text-center">
                  {loading ? (
                    <button
                      className="btn btn-block mt-2 btn-gradient"
                      disabled
                    >
                      <div className="spinner-border text-light" role="status">
                        {/* <span className="visually-hidden">Loading...</span> */}
                      </div>
                    </button>
                  ) : (
                    <button
                      className="btn btn-block mt-2 btn-gradient"
                      onClick={() => register()}
                    >
                      Create an Account
                    </button>
                  )}
                </div>

                <div className="text-center">
                  <p className="my-2 text-muted text-center">
                    --- Or connect with ---
                  </p>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="/images/wallet/MetaMask.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="/images/wallet/klever.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="/images/wallet/tokenpocket.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="/images/wallet/imtoken.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="/images/wallet/trustwallet.png" width="25" />
                  </a>
                </div>
                <div className="mt-2 text-white  text-center">
                  I have already an Account.
                  <Link to="/login">
                    {" "}
                    <span className="text-warning fw-bolder ps-3"> Login </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
