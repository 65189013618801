import React from "react";
function Hexa(props) {
  // console.log(props, "propspropspropsprops");
  return (
    <svg
      width="512"
      height="512"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <polygon
          id="svg_38"
          points="402.1000061035156,144.89999389648438 247.89999389648438,56 93.80000305175781,145.10000610351562 93.9000015258789,323.1000061035156 248.10000610351562,412 402.20001220703125,322.8999938964844 "
          className="st2"
          fill="#231F20"
        />
        <g id="svg_39">
          <g id="svg_40">
            <g id="svg_41">
              <path
                id="svg_42"
                d="m245.8,135c-26.7,0 -48.5,21.8 -48.5,48.5s21.8,48.5 48.5,48.5c26.7,0 48.5,-21.8 48.5,-48.5s-21.9,-48.5 -48.5,-48.5z"
                className="st3"
                fill="#ffffff"
              />
            </g>
          </g>
          <g id="svg_43">
            <g id="svg_44">
              <path
                id="svg_45"
                d="m329,270.8c-1.3,-3.2 -3,-6.1 -4.9,-8.9c-9.7,-14.4 -24.8,-23.9 -41.7,-26.3c-2.1,-0.2 -4.4,0.2 -6.1,1.5c-8.9,6.6 -19.5,10 -30.5,10s-21.6,-3.4 -30.5,-10c-1.7,-1.3 -4,-1.9 -6.1,-1.5c-16.9,2.3 -32.2,11.9 -41.7,26.3c-1.9,2.8 -3.6,5.9 -4.9,8.9c-0.6,1.3 -0.4,2.8 0.2,4c1.7,3 3.8,5.9 5.7,8.5c3,4 6.1,7.6 9.7,11c3,3 6.4,5.7 9.7,8.5c16.7,12.5 36.8,19.1 57.6,19.1s40.9,-6.6 57.6,-19.1c3.4,-2.5 6.8,-5.5 9.7,-8.5c3.4,-3.4 6.8,-7 9.7,-11c2.1,-2.8 4,-5.5 5.7,-8.5c1.2,-1.3 1.4,-2.8 0.8,-4z"
                className="st3"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>

        {props.length >= 1 ? (
          <g id="svg_338">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_55"
              y="366.65292"
              x="55.2647"
              stroke="#cbb42a"
              fill="#000000"
            >
              {" "}
              1 .{" "}
              {props.users.length >= 1
                ? props.users[0]?.slice(0, 4) +
                  "..." +
                  props.users[0]?.slice(-4)
                : null}
            </text>
            <g id="svg_58">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_56"
                cy="333.7853"
                cx="81.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m84.94465,332.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_57"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 2 ? (
          <g id="svg_344">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_339"
              y="121.65292"
              x="49.2647"
              stroke="#cbb42a"
              fill="#cbb42a"
            >
             
              2 .
              {props.users.length >= 2
                ? props.users[1]?.slice(0, 4) +
                  "..." +
                  props.users[1]?.slice(-4)
                : null}
            </text>
            <g id="svg_342">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_340"
                cy="146.7853"
                cx="78.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m81.94465,145.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_341"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 6 ? (
          <g id="svg_350">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_345"
              y="458.65292"
              x="223.2647"
              stroke="#cbb42a"
              fill="#000000"
            >
            
              6 .{" "}
              {props.users.length >= 6
                ? props.users[5]?.slice(0, 4) +
                  "..." +
                  props.users[5]?.slice(-4)
                : null}
            </text>
            <g id="svg_348">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_346"
                cy="425.7853"
                cx="249.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m252.94465,424.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_347"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 5 ? (
          <g id="svg_356">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_351"
              y="361.65292"
              x="390.2647"
              stroke="#cbb42a"
              fill="#000000"
            >
              {" "}
              5 .{" "}
              {props.users.length >= 5
                ? props.users[4]?.slice(0, 4) +
                  "..." +
                  props.users[4]?.slice(-4)
                : null}
            </text>
            <g id="svg_354">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_352"
                cy="328.7853"
                cx="416.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m419.94465,327.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_353"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 4 ? (
          <g id="svg_367">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_362"
              y="117.65292"
              x="389.2647"
              stroke="#cbb42a"
              fill="#000000"
            >
              {" "}
              4 .{" "}
              {props.users.length >= 4
                ? props.users[3]?.slice(0, 4) +
                  "..." +
                  props.users[3]?.slice(-4)
                : null}
            </text>
            <g id="svg_365">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_363"
                cy="142.7853"
                cx="418.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m421.94465,141.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_364"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 3 ? (
          <g id="svg_368">
            <text
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_357"
              y="22.65292"
              x="221.2647"
              stroke="#cbb42a"
              fill="#000000"
            >
              {" "}
              3 .{" "}
              {props.users.length >= 3
                ? props.users[2]?.slice(0, 4) +
                  "..." +
                  props.users[2]?.slice(-4)
                : null}
            </text>
            <g id="svg_360">
              <ellipse
                ry="13.74969"
                rx="14.49968"
                id="svg_358"
                cy="42.7853"
                cx="248.20501"
                fill="#fff"
                stroke="#000"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                fill="#000000"
                stroke="#000"
                d="m251.94465,41.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_359"
              />
            </g>
          </g>
        ) : null}
      </g>
    </svg>
  );
}
export default Hexa;
