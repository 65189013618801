import React from "react";

export default function Footer() {
  return (
    <div className="col-12 col-lg-12 mt-3">
      <footer className="site-footer">2023 © WYscale</footer>
      <a href="#" className="scrollup text-center">
        <i className="icon-arrow-up"></i>
      </a>
      </div>
  );
}
