import React, { useEffect } from "react";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import NoReacord from "./components/NoReacord";
import { getDirect } from "./helpers/api";

const Direct = () => {
  const { walletAddress } = useSelector((state) => state.user.value);
  const [directData, setDirectData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  function getDirectData(p) {
    getDirect(walletAddress, p)
      .then((d) => {
        setDirectData(d?.data);
        setTotal(d.totalData);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getDirectData(page);
  }, [page, walletAddress]);

  const handleChange = (e, p) => {
    getDirectData(p);

    setPage(p);
  };

  if (loading) return <Loader />;
  else
    return (
      <>
        {/* <!-- Direct Structure--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">My Direct Member</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Transaction ID</strong>
                      </td>
                      <td>
                        <strong>User Id</strong>
                      </td>
                      <td>
                        <strong>User Address</strong>
                      </td>

                      <td>
                        <strong>Total Buy Amount</strong>
                      </td>
                      <td>
                        <strong>Created at</strong>
                      </td>
                    </tr>
                    {directData?.map((d, index) => {
                      return (
                        <tr className="gray zoom" key={index + "direct"}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="text-success">
                              {" "}
                              <a
                                target="_blank"
                                href={`https://arbiscan.io/tx/${d?.transaction_id}`}
                              >
                                <i class="fas fa-external-link-alt text-success" ></i>
                              </a>
                            </span>
                            <span className="p-1">
                              {(d?.transaction_id).slice(0, 8) +
                                "..." +
                                (d?.transaction_id).slice(-8)}
                            </span>
                          </td>
                          <td>{d?.member_user_id}</td>
                          <td>
                            {(d?.user).slice(0, 6) +
                              "..." +
                              (d?.user).slice(-6)}
                          </td>

                          <td>{d.total_amount} ARB</td>
                          <td>
                            {" "}
                            {moment(Number(d.block_timestamp) * 1000).fromNow()}
                          </td>
                          <td style={{ color: "green" }}>{d.referral}</td>
                        </tr>
                      );
                    })}
                    {directData?.length === 0 ? (
                      <NoReacord colspan={5} message={"No Record Found !"} />
                    ) : null}
                  </tbody>
                </table>
                <hr color="#cbb42a" />
                <div className="d-sm-flex text-center justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(total / 10)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Direct;

// export default Direct;
