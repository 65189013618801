import React,{useEffect} from "react";
import { NotificationManager } from "react-notifications";
import { useSelector, connect, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { setUserExist, setWalletAddress } from "./helpers/redux/dataSlice";
import { isUserExists, startNow } from "./helpers/getweb3";
import { useLocation } from "react-router-dom";
const Login = (props) => {
  const { walletAddress } = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const route = useLocation();
  function getViewAddressFromUrl() {
    const url = route?.search;
    console.log(url,"url")
    const address = url?.substring(url?.indexOf("=") + 1);
    console.log(address,"address")
    if (address && address?.length > 25) {
      dispatch(setWalletAddress({ walletAddress: address }));

      isUserExists(address).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
      <Redirect to="/dashboard" />;
    }
  }
  function Login() {
    startNow()
      .then((res) => {
        const address = res.userAddress;
        localStorage.setItem("wallet_local", address);
        dispatch(setWalletAddress({ walletAddress: address }));
        isUserExists(address).then((res) => {
          if (res) {
            dispatch(setUserExist({ userExist: res }));
          }
          if (address) {
            if (res) {
              <Redirect to="/dashboard" />;
            } else {
              toast.error("User Not Exist !");
            }
          } else {
            toast.error("Please Connect Wallet");
          }
        });
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error getting wallet address:", error);
      });
  }
  
  useEffect(() => {
    getViewAddressFromUrl()
  }, []);

  return (
    <div
      id="main-container"
      className="default"
      style={{
        backgroundImage: "url(./images/wallet/banner.png)",
        width: "100vw",
        backgroundSize: "cover",
      }}
    >
      {/* <!-- START: Main Content--> */}
      <div className="container">
        <div className="row vh-100 justify-content-between align-items-center">
          <div className="col-12">
            <div className="row row-eq-height lockscreen  mt-5 mb-5">
              <div className=" d-flex flex-column lock-image col-12 col-sm-5 justify-content-center align-items-center">
                <Link to="/">
                  <img
                    src="./t Text.svg"
                    height="50"
                    width="100%"
                    alt=""
                    className="mb-2"
                  />
                </Link>
                <div className="form-group mb-0"></div>
              </div>
              <div className="login-form col-12 col-sm-7">
                <div className="form-group mb-3">
                  <label
                    htmlFor="walletaddress"
                    className="text-center"
                    style={{ fontSize: "16px", color: "#aed249b3" }}
                  >
                    WalletAddress
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="accountId"
                    value={walletAddress}
                    placeholder="WalletAddress"
                  />
                </div>

                <div className="form-group mb-0 text-center">
                  <button
                    className="btn btn-gradient btn-block"
                    onClick={() => Login()}
                  >
                    Automatic Login
                  </button>
                </div>

                <div className="text-center">
                  <p className="my-2 text-muted text-center">
                    --- Or connect with ---
                  </p>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="./images/wallet/MetaMask.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="./images/wallet/klever.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="./images/wallet/tokenpocket.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="./images/wallet/imtoken.png" width="25" />
                  </a>
                  <a className="btn btn-social  text-white mb-2">
                    <img src="./images/wallet/trustwallet.png" width="25" />
                  </a>
                </div>
                <div className="mt-2 text-center text-white">
                  Don't have an account?
                  <Link to="/create" className="text-warning fw-bold">
                    Create an Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
