import React from "react";

export default function Deca(props) {
  return (
    <svg
      width="500"
      height="500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <g id="deca">
          <g id="svg_1">
            <polygon
              className="st0"
              points="307.6000061035156,73.80000305175781 192.39999389648438,73.80000305175781 99.19999694824219,141.5 63.69999694824219,251 99.19999694824219,360.5 192.39999389648438,428.20001220703125 307.6000061035156,428.20001220703125 400.79998779296875,360.5 436.29998779296875,251 400.79998779296875,141.5 "
              id="svg_2"
              fill="#231F20"
            />
            <g id="svg_3">
              <g id="svg_4">
                <g id="svg_5">
                  <path
                    className="st1"
                    d="m250.3,129.4c-31.4,0 -57.2,25.7 -57.2,57.2c0,31.4 25.7,57.2 57.2,57.2c31.4,0 57.2,-25.7 57.2,-57.2c0,-31.5 -25.8,-57.2 -57.2,-57.2z"
                    id="svg_6"
                    fill="#ffffff"
                  />
                </g>
              </g>
              <g id="svg_7">
                <g id="svg_8">
                  <path
                    className="st1"
                    d="m348.4,289.4c-1.5,-3.7 -3.5,-7.2 -5.7,-10.5c-11.5,-17 -29.2,-28.2 -49.2,-30.9c-2.5,-0.2 -5.2,0.2 -7.2,1.7c-10.5,7.7 -23,11.7 -35.9,11.7s-25.5,-4 -35.9,-11.7c-2,-1.5 -4.7,-2.2 -7.2,-1.7c-20,2.7 -37.9,14 -49.2,30.9c-2.2,3.2 -4.2,7 -5.7,10.5c-0.7,1.5 -0.5,3.2 0.2,4.7c2,3.5 4.5,7 6.7,10c3.5,4.7 7.2,9 11.5,13c3.5,3.5 7.5,6.7 11.5,10c19.7,14.7 43.4,22.5 67.9,22.5s48.2,-7.7 67.9,-22.5c4,-3 8,-6.5 11.5,-10c4,-4 8,-8.2 11.5,-13c2.5,-3.2 4.7,-6.5 6.7,-10c1.1,-1.4 1.3,-3.2 0.6,-4.7z"
                    id="svg_9"
                    fill="#ffffff"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

        {props.length>=1 ?
          <g id="svg_64">
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_60"
              y="474.65292"
              x="164.2647"
              fill="#000000"
            >
              1 . {props.users.length>=1 ? props.users[0]?.slice(0,4)+"..." +props.users[0]?.slice(-4) :null}
            </text>
            <g id="svg_63">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_61"
                cy="441.7853"
                cx="190.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m193.94465,440.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_62"
              />
            </g>
          </g>
          :null}

          {props.length>=2 ?
          <g>
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_55"
              y="401.65292"
              x="59.2647"
              fill="#000000"
            >
            2 . {props.users.length>=2 ? props.users[1]?.slice(0,4)+"..." +props.users[1]?.slice(-4) :null}
            </text>
            <g id="svg_58">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_56"
                cy="368.7853"
                cx="85.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m88.94465,367.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_57"
              />
            </g>
          </g>
          :null}

          {props.length>=3 ?
          <g id="svg_30">
            <text
              stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_65"
              y="284.65292"
              x="15.2647"
              fill="#000000"
            >
              3 . {props.users.length>=3 ? props.users[2]?.slice(0,4)+"..." +props.users[2]?.slice(-4) :null}
            </text>
            <g id="svg_68">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_66"
                cy="251.7853"
                cx="45.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m48.94465,250.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_67"
              />
            </g>
          </g>
          :null}

          {props.length>=4 ?
          <g>
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_70"
              y="116.65292"
              x="54.2647"
              fill="#000000"
            >
             4 . {props.users.length>=4 ? props.users[3]?.slice(0,4)+"..." +props.users[3]?.slice(-4) :null}
            </text>
            <g id="svg_73">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_71"
                cy="139.7853"
                cx="83.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m86.94465,138.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_72"
              />
            </g>
          </g>
          :null}

          {props.length>=5 ?
          <g id="svg_33">
            <text
             stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_75"
              y="39.65292"
              x="162.2647"
              fill="#000000"
            >
            5 . {props.users.length>=5 ? props.users[4]?.slice(0,4)+"..." +props.users[4]?.slice(-4) :null}
            </text>
            <g id="svg_78">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_76"
                cy="59.7853"
                cx="190.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m193.94465,58.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_77"
              />
            </g>
          </g>
          :null}

          {props.length>=6 ?
          <g id="svg_32">
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_80"
              y="37.65292"
              x="276.2647"
              fill="#000000"
            >
            6 . {props.users.length>=6 ? props.users[5]?.slice(0,4)+"..." +props.users[5]?.slice(-4) :null}
            </text>
            <g id="svg_83">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_81"
                cy="58.7853"
                cx="304.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m307.94465,57.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_82"
              />
            </g>
          </g>
          :null}

          {props.length>=7 ?
          <g>
            <text
              stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_10"
              y="116.65292"
              x="385.2647"
              fill="#000000"
            >
             7 . {props.users.length>=7 ? props.users[6]?.slice(0,4)+"..." +props.users[6]?.slice(-4) :null}
            </text>
            <g id="svg_13">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_11"
                cy="136.7853"
                cx="416.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m419.94465,135.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_12"
              />
            </g>
          </g>
          :null}

          {props.length>=8 ?
          <g id="svg_31">
            <text
             stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_15"
              y="282.65292"
              x="430.2647"
              fill="#000000"
            >
            8 . {props.users.length>=8 ? props.users[7]?.slice(0,4)+"..." +props.users[7]?.slice(-4) :null}
            </text>
            <g id="svg_18">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_16"
                cy="249.7853"
                cx="454.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m457.94465,248.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_17"
              />
            </g>
          </g>
          :null}

          {props.length>=9 ?
          <g>
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_20"
              y="396.65292"
              x="389.2647"
              fill="#000000"
            >
          9 . {props.users.length>=9 ? props.users[8]?.slice(0,4)+"..." +props.users[8]?.slice(-4) :null}
            </text>
            <g id="svg_23">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_21"
                cy="365.7853"
                cx="415.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m418.94465,364.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_22"
              />
            </g>
          </g>
          :null}
          {props.length>=10 ?
          <g id="svg_34">
            <text
               stroke="#cbb42a"
              textAnchor="start"
              fontFamily="sans-serif"
              fontSize="16"
              id="svg_25"
              y="476.65292"
              x="284.2647"
              fill="#000000"
            >
              10 . {props.users.length>=10 ? props.users[9]?.slice(0,4)+"..." +props.users[9]?.slice(-4) :null}
            </text>
            <g id="svg_28">
              <ellipse
                stroke="#000"
                ry="13.74969"
                rx="14.49968"
                id="svg_26"
                cy="443.7853"
                cx="310.20501"
                fill="#fff"
                strokeWidth="2"
                strokeDasharray="2,2"
              />
              <path
                stroke="#000"
                fill="#000000"
                d="m313.94465,442.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                id="svg_27"
              />
            </g>
          </g>
          :null}
      
      </g>
    </svg>
  );
}
