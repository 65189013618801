import React from "react";
// import { connect } from 'react-redux'

export const PromoContent = (props) => {
  return (
    <>
      <div className="col-12  mt-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <ul className="filter p-0">
                  <li className="d-inline-block">
                    <a
                      className="filter-button active"
                      href="#"
                      data-group="all"
                    >
                      All
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="#" className="filter-button" data-group="Branding">
                      Videos
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="#"
                      className="filter-button"
                      data-group="Designing"
                    >
                      Images
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="#"
                      className="filter-button"
                      data-group="Photography"
                    >
                      Documents
                    </a>
                  </li>
                </ul>
              </div>
              <div className="clearfix"></div>
            </div>
            <div id="grid" className="row">
              <div className="item col-12 col-md-6 col-lg-4 mb-4 text-center Branding">
                <img
                  src="./imagesdist/images/portfolio1.jpg"
                  alt=""
                  className="portfolioImage img-fluid"
                />
                <div className="d-flex">
                  <a
                    data-fancybox-group="gallery"
                    href="dist/images/portfolio1.jpg"
                    className="fancybox btn rounded-0 btn-dark w-50"
                  >
                    View Large
                  </a>
                  <a href="#" className="btn btn-primary rounded-0 w-50">
                    More Details
                  </a>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Designing">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio2.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio2.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Photography">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio3.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio3.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Development">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio4.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio4.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Designing">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio5.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio5.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Photography">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio6.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio6.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>

              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Branding">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio13.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio13.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Designing">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio14.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio14.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Photography">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio15.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio15.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Development">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio16.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio16.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Designing">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio17.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio17.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
              <div className="item col-12 col-md-6 col-lg-4 mb-4 cation-hover text-center Photography">
                <div className="modImage">
                  <img
                    src="./imagesdist/images/portfolio18.jpg"
                    alt=""
                    className="portfolioImage img-fluid"
                  />
                  <div className="d-flex">
                    <a
                      data-fancybox-group="gallery"
                      href="dist/images/portfolio18.jpg"
                      className="fancybox btn rounded-0 btn-dark w-50"
                    >
                      View Large
                    </a>
                    <a href="#" className="btn btn-primary rounded-0 w-50">
                      More Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = (state) => ({

// })

// const mapDispatchToProps = {

// }

// export default connect(mapStateToProps, mapDispatchToProps)(PromoContent)

export default PromoContent;
