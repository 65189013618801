import React from "react";

export default function Octa(props) {
  return (
    <svg
      width="512"
      height="512"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <g id="octa">
          <g id="svg_19">
            <polygon
              id="svg_20"
              points="384.1000061035156,131.8000030517578 261.5,74 133.89999389648438,119.89999389648438 76,242.5 121.89999389648438,370.20001220703125 244.5,428 372.1000061035156,382.1000061035156 430,259.5 "
              className="st2"
              fill="#231F20"
            />
            <g id="svg_21">
              <g id="svg_22">
                <g id="svg_23">
                  <path
                    id="svg_24"
                    d="m254.4,147.8c-26.9,0 -49,22 -49,49s22,49 49,49c26.9,0 49,-22 49,-49s-22.1,-49 -49,-49z"
                    className="st3"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
              <g id="svg_25">
                <g id="svg_26">
                  <path
                    id="svg_27"
                    d="m338.4,284.9c-1.3,-3.2 -3,-6.2 -4.9,-9c-9.8,-14.5 -25,-24.2 -42.1,-26.5c-2.1,-0.2 -4.5,0.2 -6.2,1.5c-9,6.6 -19.7,10 -30.8,10s-21.8,-3.4 -30.8,-10c-1.7,-1.3 -4.1,-1.9 -6.2,-1.5c-17.1,2.4 -32.5,12 -42.1,26.5c-1.9,2.8 -3.6,6 -4.9,9c-0.6,1.3 -0.4,2.8 0.2,4.1c1.7,3 3.8,6 5.8,8.6c3,4.1 6.2,7.7 9.8,11.1c3,3 6.4,5.8 9.8,8.6c16.9,12.6 37.2,19.2 58.2,19.2c21,0 41.3,-6.6 58.2,-19.2c3.4,-2.6 6.8,-5.6 9.8,-8.6c3.4,-3.4 6.8,-7.1 9.8,-11.1c2.1,-2.8 4.1,-5.6 5.8,-8.6c1,-1.3 1.2,-2.8 0.6,-4.1z"
                    className="st3"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

        {/* #1 */}
        {props.length >= 1 ? (
          <g id="svg_356">
            <text
              fill="#000000"
              x="85.2647"
              y="415.65292"
              id="svg_55"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              1 .{" "}
              {props.users.length >= 1
                ? props.users[0]?.slice(0, 4) +
                  "..." +
                  props.users[0]?.slice(-4)
                : null}
            </text>
            <g id="svg_58">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="111.20501"
                cy="382.7853"
                id="svg_56"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_57"
                d="m114.94465,381.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {/* #2 */}

        {props.length >= 2 ? (
          <g id="svg_100">
            <text
              fill="#000000"
              x="32.2647"
              y="279.65292"
              id="svg_65"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              2 .{" "}
              {props.users.length >= 2
                ? props.users[1]?.slice(0, 4) +
                  "..." +
                  props.users[1]?.slice(-4)
                : null}
            </text>
            <g id="svg_68">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="60.20501"
                cy="246.7853"
                id="svg_66"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_67"
                d="m63.94465,245.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {/* #3 */}
        {props.length >= 3 ? (
          <g>
            <text
              fill="#000000"
              x="90.2647"
              y="99.65292"
              id="svg_70"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              3 .{" "}
              {props.users.length >= 3
                ? props.users[2]?.slice(0, 4) +
                  "..." +
                  props.users[2]?.slice(-4)
                : null}
            </text>
            <g id="svg_73">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="116.20501"
                cy="119.7853"
                id="svg_71"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_72"
                d="m119.94465,118.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
        {props.length >= 4 ? (
          <g id="svg_101">
            <text
              fill="#000000"
              x="235.2647"
              y="40.65292"
              id="svg_75"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              4 .{" "}
              {props.users.length >= 4
                ? props.users[3]?.slice(0, 4) +
                  "..." +
                  props.users[3]?.slice(-4)
                : null}
            </text>
            <g id="svg_78">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="262.20501"
                cy="58.7853"
                id="svg_76"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_77"
                d="m265.94465,57.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 5 ? (
          <g>
            <text
              fill="#000000"
              x="374.2647"
              y="111.65292"
              id="svg_80"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              5 .{" "}
              {props.users.length >= 5
                ? props.users[4]?.slice(0, 4) +
                  "..." +
                  props.users[4]?.slice(-4)
                : null}
            </text>
            <g id="svg_83">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="401.20501"
                cy="130.7853"
                id="svg_81"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_82"
                d="m404.94465,129.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 6 ? (
          <g id="svg_102">
            <text
              fill="#000000"
              x="420.2647"
              y="291.65292"
              id="svg_85"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              6 .{" "}
              {props.users.length >= 6
                ? props.users[5]?.slice(0, 4) +
                  "..." +
                  props.users[5]?.slice(-4)
                : null}
            </text>
            <g id="svg_88">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="446.20501"
                cy="258.7853"
                id="svg_86"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_87"
                d="m449.94465,257.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 7 ? (
          <g>
            <text
              fill="#000000"
              x="356.2647"
              y="426.65292"
              id="svg_90"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              7 .{" "}
              {props.users.length >= 7
                ? props.users[6]?.slice(0, 4) +
                  "..." +
                  props.users[6]?.slice(-4)
                : null}
            </text>
            <g id="svg_93">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="382.20501"
                cy="393.7853"
                id="svg_91"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_92"
                d="m385.94465,392.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}

        {props.length >= 8 ? (
          <g id="svg_103">
            <text
              fill="#000000"
              x="218.2647"
              y="475.65292"
              id="svg_95"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              stroke="#cbb42a"
            >
              8 .{" "}
              {props.users.length >= 8
                ? props.users[7]?.slice(0, 4) +
                  "..." +
                  props.users[7]?.slice(-4)
                : null}
            </text>
            <g id="svg_98">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                fill="#fff"
                cx="244.20501"
                cy="442.7853"
                id="svg_96"
                rx="14.49968"
                ry="13.74969"
                stroke="#000"
              />
              <path
                id="svg_97"
                d="m247.94465,441.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                fill="#000000"
                stroke="#000"
              />
            </g>
          </g>
        ) : null}
      </g>
    </svg>
  );
}
