import Web3 from "web3";
import toast, { Toaster } from "react-hot-toast";

import {
  CONTRACT_ADDRESS,
  TOKEN_ADDRESS,
  CONTRACT_ABI,
  TOKEN_ABI,
  TOKEN_ABI2,
  TOKEN_ADDRESS2,
} from "./config";

const web3 = new Web3(Web3.givenProvider);
const contract_instance = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
const token_instance = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);
const token_instance2 = new web3.eth.Contract(TOKEN_ABI2, TOKEN_ADDRESS2);
export function getweb3() {
  if (web3) {
    console.log("web3", web3);
    return web3;
  }
}
// chain id 42161 arbitrum
// Arbitrum Mainnet Network
export async function startNow() {
  return new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      const chain = await web3.eth.getChainId();
      if (chain != 42161) {
        toast.error(" Please Connect : Arbitrum Mainnet Network");
        return;
      }

      const handleAccountsChanged = (accounts) => {
        const userAddress = accounts[0];
        resolve({
          userAddress,
        });
      };

      window.ethereum.on("accountsChanged", handleAccountsChanged);

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const userAddress = accounts[0];
        resolve({
          userAddress,
        });
      } catch (error) {
        reject(error);
      }
    } else {
      toast.error("Open in Dapp Browser or Install Any Crypto Wallet");
    }
  });
}

// export async function registerExt(
//   address,
//   refAddress,
//   amount,
//   setRefresh,
//   setLoading,
//   history
// ) {
//   try {
//     setLoading(true);

//     token_instance.methods
//       .balanceOf(address)
//       .call()
//       .then((res) => {
//         const userBalance = res / 1e18;
//         console.log(res, "userBalance");
//         // if (userBalance >= amount) {
//         if (userBalance >= amount) {
//           token_instance.methods
//             .allowance(address, contract_instance._address)
//             .call()
//             .then(async (res) => {
//               const allowance = res / 1e18;

//               if (allowance >= amount) {
//                 try {
//                   console.log(refAddress, "refId");
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const gas = await contract_instance.methods
//                     .registration(refAddress)
//                     .estimateGas({
//                       from: address,
//                       value: 5 * 1e18,
//                       gasPrice: gasPrice,
//                     });

//                   const data = contract_instance.methods
//                     .registration(refAddress)
//                     .send({
//                       from: address,
//                       value: 5 * 1e18,
//                       gasPrice: gasPrice,
//                       gas: gas,
//                     });
//                   toast
//                     .promise(data, {
//                       loading: <b>registeration Pending...</b>,
//                       success: () => {
//                         return <b>registeration Successful</b>;
//                       },
//                       error: <b>registeration Canceld.</b>,
//                     })
//                     .then((d) => {
//                       setLoading(false);
//                       setRefresh(true);
//                       history.push("/dashboard");
//                     })
//                     .catch((error) => {
//                       setLoading(false);
//                       console.log(error, "Error");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "Error");
//                 }
//               } else if (allowance < amount) {
//                 console.log("6");
//                 console.log((amount * 1e18).toString(), "amount*1e18 3");
//                 try {
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const approveGas = await token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .estimateGas({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                     });
//                   const approveData = token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .send({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                       gas: approveGas,
//                     });
//                   toast
//                     .promise(approveData, {
//                       loading: <b>Approval Pending...</b>,
//                       success: <b>Approval Successful</b>,
//                       error: <b>Approval request failed.</b>,
//                     })
//                     .then(async () => {
//                       const gas = await contract_instance.methods
//                         .registration(refAddress)
//                         .estimateGas({
//                           from: address,
//                           value: 5 * 1e18,
//                           gasPrice: gasPrice,
//                         });
//                       const data = contract_instance.methods
//                         .registration(refAddress)
//                         .send({
//                           from: address,
//                           value: 5 * 1e18,
//                           gas: gas,
//                           gasPrice: gasPrice,
//                         });
//                       toast
//                         .promise(data, {
//                           loading: <b> Transaction Pending...</b>,
//                           success: () => {
//                             setLoading(true);
//                             setRefresh(true);

//                             history.push("/dashboard");
//                             return <b>Transaction Successful</b>;
//                           },
//                           error: <b>Transaction Canceld.</b>,
//                         })
//                         .then((d) => {
//                           setLoading(false);
//                           setRefresh(true);

//                           history.push("/dashboard");
//                         })
//                         .catch((error) => {
//                           setLoading(false);
//                           console.log(error, "Error1111");
//                         });
//                     })
//                     .catch((err) => {
//                       setLoading(false);
//                       console.log(err, "Err-4");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "approval Error");
//                 }
//               }
//             })
//             .catch((err) => {
//               setLoading(false);
//               console.log(err, "Err-2");
//             });
//         } else {
//           setLoading(false);
//           toast.error("Insufficient Balance !");
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         console.log(err, "2 catch");
//       });
//   } catch (error) {
//     setLoading(false);
//     console.log(error, "1  catch");
//   }
// }

// export async function upgradeStageBuy(address, amount, setRefresh, setLoading) {
//   try {
//     // console.log(address, amount, "register");
//     setLoading(true);

//     token_instance.methods
//       .balanceOf(address)
//       .call()
//       .then((res) => {
//         const userBalance = res / 1e18;
//         console.log(res, "userBalance");
//         // if (userBalance >= amount) {
//         if (userBalance >= amount) {
//           token_instance.methods
//             .allowance(address, contract_instance._address)
//             .call()
//             .then(async (res) => {
//               const allowance = res / 1e18;

//               if (allowance >= amount) {
//                 try {
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const gas = await contract_instance.methods
//                     .buyStagePlan()
//                     .estimateGas({
//                       from: address,
//                       value: 10 * 1e18,
//                       gasPrice: gasPrice,
//                     });

//                   const data = contract_instance.methods.buyStagePlan().send({
//                     from: address,
//                     value: 10 * 1e18,
//                     gasPrice: gasPrice,
//                     gas: gas,
//                   });
//                   toast
//                     .promise(data, {
//                       loading: <b>Transaction Pending...</b>,
//                       success: () => {
//                         return <b>Transaction Successful</b>;
//                       },
//                       error: <b>Transaction Canceld.</b>,
//                     })
//                     .then((d) => {
//                       setLoading(false);
//                       setRefresh(true);
//                     })
//                     .catch((error) => {
//                       setLoading(false);
//                       console.log(error, "Error");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "Error");
//                 }
//               } else if (allowance < amount) {
//                 console.log("6");
//                 console.log((amount * 1e18).toString(), "amount*1e18 3");
//                 try {
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const approveGas = await token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .estimateGas({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                     });
//                   const approveData = token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .send({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                       gas: approveGas,
//                     });
//                   toast
//                     .promise(approveData, {
//                       loading: <b>Approval Pending...</b>,
//                       success: <b>Approval Successful</b>,
//                       error: <b>Approval request failed.</b>,
//                     })
//                     .then(async () => {
//                       const gas = await contract_instance.methods
//                         .buyStagePlan()
//                         .estimateGas({
//                           from: address,
//                           value: 10 * 1e18,
//                           gasPrice: gasPrice,
//                         });
//                       const data = contract_instance.methods
//                         .buyStagePlan()
//                         .send({
//                           from: address,
//                           value: 10 * 1e18,
//                           gas: gas,
//                           gasPrice: gasPrice,
//                         });
//                       toast
//                         .promise(data, {
//                           loading: <b> Transaction Pending...</b>,
//                           success: () => {
//                             setLoading(true);
//                             setRefresh(true);
//                             return <b>Transaction Successful</b>;
//                           },
//                           error: <b>Transaction Canceld.</b>,
//                         })
//                         .then((d) => {
//                           setLoading(false);
//                           setRefresh(true);
//                         })
//                         .catch((error) => {
//                           setLoading(false);
//                           console.log(error, "Error1111");
//                         });
//                     })
//                     .catch((err) => {
//                       setLoading(false);
//                       console.log(err, "Err-4");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "approval Error");
//                 }
//               }
//             })
//             .catch((err) => {
//               setLoading(false);
//               console.log(err, "Err-2");
//             });
//         } else {
//           setLoading(false);
//           toast.error("Insufficient Balance !");
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         console.log(err, "2 catch");
//       });
//   } catch (error) {
//     setLoading(false);
//     console.log(error, "1  catch");
//   }
// }

// export async function upgradeGeometryBuy(
//   address,
//   amount,
//   setRefresh2,
//   setLoading
// ) {
//   try {
//     setLoading(true);

//     token_instance.methods
//       .balanceOf(address)
//       .call()
//       .then((res) => {
//         const userBalance = res / 1e18;
//         console.log(res, "userBalance");
//         // if (userBalance >= amount) {
//         if (userBalance >= amount) {
//           token_instance.methods
//             .allowance(address, contract_instance._address)
//             .call()
//             .then(async (res) => {
//               const allowance = res / 1e18;

//               if (allowance >= amount) {
//                 try {
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const gas = await contract_instance.methods
//                     .buyGeomatry()
//                     .estimateGas({
//                       from: address,
//                       value: 25 * 1e18,
//                       gasPrice: gasPrice,
//                     });

//                   const data = contract_instance.methods.buyGeomatry().send({
//                     from: address,
//                     value: 25 * 1e18,
//                     gasPrice: gasPrice,
//                     gas: gas,
//                   });
//                   toast
//                     .promise(data, {
//                       loading: <b>registeration Pending...</b>,
//                       success: () => {
//                         return <b>registeration Successful</b>;
//                       },
//                       error: <b>registeration Canceld.</b>,
//                     })
//                     .then((d) => {
//                       setLoading(false);
//                       setRefresh2(true);
//                     })
//                     .catch((error) => {
//                       setLoading(false);
//                       console.log(error, "Error");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "Error");
//                 }
//               } else if (allowance < amount) {
//                 console.log("6");
//                 console.log((amount * 1e18).toString(), "amount*1e18 3");
//                 try {
//                   const gasPrice = await web3.eth.getGasPrice();
//                   const approveGas = await token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .estimateGas({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                     });
//                   const approveData = token_instance.methods
//                     .approve(
//                       contract_instance?._address,
//                       (amount * 1e18).toLocaleString("fullwide", {
//                         useGrouping: false,
//                       })
//                     )
//                     .send({
//                       from: address,
//                       value: 0,
//                       gasPrice: gasPrice,
//                       gas: approveGas,
//                     });
//                   toast
//                     .promise(approveData, {
//                       loading: <b>Approval Pending...</b>,
//                       success: <b>Approval Successful</b>,
//                       error: <b>Approval request failed.</b>,
//                     })
//                     .then(async () => {
//                       const gas = await contract_instance.methods
//                         .buyGeomatry()
//                         .estimateGas({
//                           from: address,
//                           value: 25 * 1e18,
//                           gasPrice: gasPrice,
//                         });
//                       const data = contract_instance.methods
//                         .buyGeomatry()
//                         .send({
//                           from: address,
//                           value: 25 * 1e18,
//                           gas: gas,
//                           gasPrice: gasPrice,
//                         });
//                       toast
//                         .promise(data, {
//                           loading: <b> Transaction Pending...</b>,
//                           success: () => {
//                             setLoading(true);
//                             setRefresh2(true);
//                             return <b>Transaction Successful</b>;
//                           },
//                           error: <b>Transaction Canceld.</b>,
//                         })
//                         .then((d) => {
//                           setLoading(false);
//                           setRefresh2(true);
//                         })
//                         .catch((error) => {
//                           setLoading(false);
//                           console.log(error, "Error1111");
//                         });
//                     })
//                     .catch((err) => {
//                       setLoading(false);
//                       console.log(err, "Err-4");
//                     });
//                 } catch (error) {
//                   setLoading(false);
//                   console.log(error, "approval Error");
//                 }
//               }
//             })
//             .catch((err) => {
//               setLoading(false);
//               console.log(err, "Err-2");
//             });
//         } else {
//           setLoading(false);
//           toast.error("Insufficient Balance !");
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         console.log(err, "2 catch");
//       });
//   } catch (error) {
//     setLoading(false);
//     console.log(error, "1  catch");
//   }
// }

export async function isUserExists(address) {
  const data = await contract_instance.methods.isExist(address).call();
  return data;
}

export async function getUserInfo(address) {
  const data = await contract_instance.methods.usersData(address).call();
  return data;
}

export function getBalance(userAddress) {
  return new Promise((resolve, reject) => {
    web3.eth
      .getBalance(userAddress)
      .then((d) => {
        resolve(d / 1e18);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function idToAddress(id) {
  try {
    const data = await contract_instance.methods.idToAddress(id).call();
    return data;
  } catch (e) {
    console.log(e, "error in idtoaddress metohd");
  }
}

export async function addressToId(addr) {
  try {
    const data = await contract_instance.methods.addressToId(addr).call();
    return data;
  } catch (e) {
    console.log(e, "error in idtoaddress metohd");
  }
}

export async function getUserProgressInfo(addr) {
  try {
    const data = await contract_instance.methods.usersData(addr).call();
    let progress = 0;
    if (data.isStagePurchased) {
      progress = 1;
    }
    if (data.geometryUnlocked == 5) {
      progress = 2;
    }
    if (data.geometryUnlocked == 6) {
      progress = 3;
    }
    if (data.geometryUnlocked == 7) {
      progress = 4;
    }
    if (data.geometryUnlocked == 8) {
      progress = 5;
    }
    if (data.geometryUnlocked == 9) {
      progress = 6;
    }
    if (data.geometryUnlocked == 10) {
      progress = 7;
    }
    return progress;
  } catch (e) {
    console.log(e, "error in idtoaddress method");
  }
}

export async function getGeometryStructure(addr, len) {
  try {
    const data = await contract_instance.methods
      .getCurrentUser(addr, len)
      .call();

    return data;
  } catch (e) {
    console.log(e, "error in idtoaddress metohd");
  }
}

export async function claimAmount(address, setRefreshClaim) {
  try {
    console.log(address, "with::::");
    const data = contract_instance.methods
      .claimIncome()
      .send({ from: address });
    toast.promise(data, {
      loading: <b>Claim Pending...</b>,
      success: () => {
        setRefreshClaim(true);
        return <b>Claim Successful</b>;
      },
      error: <b>Claim Canceld.</b>,
    });
  } catch (error) {
    console.log(error, "Error");
  }
}

export async function registerExt(
  address,
  refAddress,
  amount,
  setRefresh,
  setLoading,
  history
) {
  try {
    setLoading(true);
    const userBalance1 =
      (await token_instance.methods.balanceOf(address).call()) / 1e18;
    // console.log(userBalance1, "userBalance1");

    // Check balance for the second token
    const userBalance2 =
      (await token_instance2.methods.balanceOf(address).call()) / 1e18;
    // console.log(userBalance2, "userBalance2");

    if (userBalance1 >= amount) {
      if (userBalance2 >= amount) {
        // console.log("calL:::");
        const allowance1 =
          (await token_instance.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
        // console.log(allowance1, "allowance1:::");
        // Check allowance for the second token
        const allowance2 =
          (await token_instance2.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
        // console.log(allowance2, "allowance2:::");
        if (allowance1 >= amount && allowance2 >= amount) {
          try {
            // console.log(refAddress, "refId");
            const gasPrice = await web3.eth.getGasPrice();
            const gas = await contract_instance.methods
              .registration(refAddress)
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const data = contract_instance.methods
              .registration(refAddress)
              .send({
                from: address,
                value: 0,
                gasPrice: gasPrice,
                gas: gas,
              });
            toast
              .promise(data, {
                loading: <b>registeration Pending...</b>,
                success: () => {
                  return <b>registeration Successful</b>;
                },
                error: <b>registeration Canceld.</b>,
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                history.push("/dashboard");
              })
              .catch((error) => {
                setLoading(false);
                console.log(error, "Error");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "Error");
          }
        } else {
          // console.log("else cal:::");

          // console.log("6");

          try {
            const gasPrice = await web3.eth.getGasPrice();
            const approveGas = await token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const approveData = token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .send({
                from: address,
                value: 0,
                gasPrice: gasPrice,
                gas: approveGas,
              });

            toast
              .promise(approveData, {
                loading: <b>Approval Pending...</b>,
                success: <b>Approval Successful</b>,
                error: <b>Approval request failed.</b>,
              })
              .then(async () => {
                try {
                  console.log("call than :::");

                  const gasPrice = await web3.eth.getGasPrice();
                  const approveGas = await token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const approveData = token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: approveGas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      const gas = await contract_instance.methods
                        .registration(refAddress)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .registration(refAddress)
                        .send({
                          from: address,
                          value: 0,
                          gas: gas,
                          gasPrice: gasPrice,
                        });
                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            setLoading(true);
                            setRefresh(true);

                            history.push("/dashboard");
                            return <b>Transaction Successful</b>;
                          },
                          error: <b>Transaction Canceld.</b>,
                        })
                        .then((d) => {
                          setLoading(false);
                          setRefresh(true);

                          history.push("/dashboard");
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(error, "Error1111");
                        });
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setLoading(false);
                  console.log(error, "approval Error");
                }
              })
              .catch((err) => {
                setLoading(false);
                console.log(err, "Err-4");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "approval Error");
          }
        }
      } else {
        setLoading(false);
        toast.error("Insufficient Balance : ARB !");
      }
    } else {
      setLoading(false);
      toast.error("Insufficient Balance :  wARB   !");
    }
  } catch (error) {
    setLoading(false);
    console.log(error, "1  catch");
  }
}

export async function upgradeStageBuy(address, amount, setRefresh, setLoading) {
  try {
    setLoading(true);
    const userBalance1 =
      (await token_instance.methods.balanceOf(address).call()) / 1e18;
   

    // Check balance for the second token
    const userBalance2 =
      (await token_instance2.methods.balanceOf(address).call()) / 1e18;
    

    if (userBalance1 >= amount) {
      if (userBalance2 >= amount) {
      
        const allowance1 =
          (await token_instance.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
       
        // Check allowance for the second token
        const allowance2 =
          (await token_instance2.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
       
        if (allowance1 >= amount && allowance2 >= amount) {
          try {
            // console.log(refAddress, "refId");
            const gasPrice = await web3.eth.getGasPrice();
            const gas = await contract_instance.methods
              .buyStagePlan()
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const data = contract_instance.methods.buyStagePlan().send({
              from: address,
              value: 0,
              gasPrice: gasPrice,
              gas: gas,
            });
            toast
              .promise(data, {
                loading: <b>Transaction Pending...</b>,
                success: () => {
                  return <b>Transaction Successful</b>;
                },
                error: <b>Transaction Canceld.</b>,
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
              })
              .catch((error) => {
                setLoading(false);
                console.log(error, "Error");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "Error");
          }
        } else {
          console.log("else cal:::");

          console.log("6");

          try {
            const gasPrice = await web3.eth.getGasPrice();
            const approveGas = await token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const approveData = token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .send({
                from: address,
                value: 0,
                gasPrice: gasPrice,
                gas: approveGas,
              });

            toast
              .promise(approveData, {
                loading: <b>Approval Pending...</b>,
                success: <b>Approval Successful</b>,
                error: <b>Approval request failed.</b>,
              })
              .then(async () => {
                try {
                  console.log("call than :::");

                  const gasPrice = await web3.eth.getGasPrice();
                  const approveGas = await token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const approveData = token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: approveGas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      const gas = await contract_instance.methods
                        .buyStagePlan()
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .buyStagePlan()
                        .send({
                          from: address,
                          value: 0,
                          gas: gas,
                          gasPrice: gasPrice,
                        });
                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            setLoading(true);
                            setRefresh(true);

                            return <b>Transaction Successful</b>;
                          },
                          error: <b>Transaction Canceld.</b>,
                        })
                        .then((d) => {
                          setLoading(false);
                          setRefresh(true);
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(error, "Error1111");
                        });
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setLoading(false);
                  console.log(error, "approval Error");
                }
              })
              .catch((err) => {
                setLoading(false);
                console.log(err, "Err-4");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "approval Error");
          }
        }
      } else {
        setLoading(false);
        toast.error("Insufficient Balance ARB !");
      }
    } else {
      setLoading(false);
      toast.error("Insufficient Balance : wARB  !");
    }
  } catch (error) {
    setLoading(false);
    console.log(error, "1  catch");
  }
}

export async function upgradeGeometryBuy(
  address,
  amount,
  setRefresh2,
  setLoading
) {
  try {
    setLoading(true);
    const userBalance1 =
      (await token_instance.methods.balanceOf(address).call()) / 1e18;
  

    // Check balance for the second token
    const userBalance2 =
      (await token_instance2.methods.balanceOf(address).call()) / 1e18;
   

    if (userBalance1 >= amount) {
      if (userBalance2 >= amount) {
       
        const allowance1 =
          (await token_instance.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
        console.log(allowance1, "allowance1:::");
        // Check allowance for the second token
        const allowance2 =
          (await token_instance2.methods
            .allowance(address, contract_instance._address)
            .call()) / 1e18;
        console.log(allowance2, "allowance2:::");
        if (allowance1 >= amount && allowance2 >= amount) {
          try {
            // console.log(refAddress, "refId");
            const gasPrice = await web3.eth.getGasPrice();
            const gas = await contract_instance.methods
              .buyGeomatry()
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const data = contract_instance.methods.buyGeomatry().send({
              from: address,
              value: 0,
              gasPrice: gasPrice,
              gas: gas,
            });
            toast
              .promise(data, {
                loading: <b>Transaction Pending...</b>,
                success: () => {
                  return <b>Transaction Successful</b>;
                },
                error: <b>Transaction Canceld.</b>,
              })
              .then((d) => {
                setLoading(false);
                setRefresh2(true);
              })
              .catch((error) => {
                setLoading(false);
                console.log(error, "Error");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "Error");
          }
        } else {
         

          try {
            const gasPrice = await web3.eth.getGasPrice();
            const approveGas = await token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .estimateGas({
                from: address,
                value: 0,
                gasPrice: gasPrice,
              });

            const approveData = token_instance.methods
              .approve(
                contract_instance?._address,
                (amount * 1e18).toLocaleString("fullwide", {
                  useGrouping: false,
                })
              )
              .send({
                from: address,
                value: 0,
                gasPrice: gasPrice,
                gas: approveGas,
              });

            toast
              .promise(approveData, {
                loading: <b>Approval Pending...</b>,
                success: <b>Approval Successful</b>,
                error: <b>Approval request failed.</b>,
              })
              .then(async () => {
                try {
                 
                  const gasPrice = await web3.eth.getGasPrice();
                  const approveGas = await token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const approveData = token_instance2.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: approveGas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      const gas = await contract_instance.methods
                        .buyGeomatry()
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .buyGeomatry()
                        .send({
                          from: address,
                          value: 0,
                          gas: gas,
                          gasPrice: gasPrice,
                        });
                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            setLoading(true);
                            setRefresh2(true);

                            return <b>Transaction Successful</b>;
                          },
                          error: <b>Transaction Canceld.</b>,
                        })
                        .then((d) => {
                          setLoading(false);
                          setRefresh2(true);
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(error, "Error1111");
                        });
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setLoading(false);
                  console.log(error, "approval Error");
                }
              })
              .catch((err) => {
                setLoading(false);
                console.log(err, "Err-4");
              });
          } catch (error) {
            setLoading(false);
            console.log(error, "approval Error");
          }
        }
      } else {
        setLoading(false);
        toast.error("Insufficient Balance : ARB !");
      }
    } else {
      setLoading(false);
      toast.error("Insufficient Balance : wARB !");
    }
  } catch (error) {
    setLoading(false);
    console.log(error, "1  catch");
  }
}
