import React, { useEffect } from "react";
import {
  getTopMarketorData,
  getTodayTopmarketData,
} from "./helpers/apiFunctions";
import Loader from "./components/Loader";
import NoRecord from './components/NoReacord';
// import { connect } from 'react-redux'

export const TopMarketer = () => {
  const [topmarketData, setTopmarketData] = React.useState();
  const [todaymarketData, setTodaymarketData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getTopMarketorData()
      .then((d) => {
        console.log("top market", d);
        setTopmarketData(d);
        getTodayTopmarketData()
          .then((data) => {
            console.log("today market", data);
            setTodaymarketData(data);
            setTimeout(() => setLoading(false), 1000);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">Last 24 Hrs. Top Marketer</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Direct Reff Count</strong>
                      </td>
                      <td>
                        <strong>Chain Reff. Count</strong>
                      </td>
                      <td>
                        <strong>Total Reff. Income</strong>
                      </td>
                    </tr>
                    {
                      todaymarketData?.map((d, index) => {
                        return (
                          <tr className="gray zoom" key={index}>
                            <td>{index + 1}</td>
                            <td>{d.id}</td>
                            <td>{window.tronWeb?.address.fromHex(d.wallet)}</td>
                            <td>{d.refCount}</td>
                            <td style={{ color: "green" }}>
                              {d.total_member}{" "}
                            </td>
                            <td>{d.refIncome} BTT </td>
                          </tr>
                        );
                      })}
                      {todaymarketData?.length == 0 && (
                      <NoRecord colspan={6} message={"No Record Found !"} />
                    )}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- System Top Marketer--> */}
        <div className="col-12 col-lg-12 mt-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title">System Top Marketer</h6>
            </div>
            <div className="card-content">
              <div className="card-body p-0 table-responsive">
                <table className="table">
                  <tbody id="transactionHistoryTrns">
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>
                        <strong>ID</strong>
                      </td>
                      <td>
                        <strong>Wallet</strong>
                      </td>
                      <td>
                        <strong>Direct Reff Count</strong>
                      </td>
                      <td>
                        <strong>Chain Reff. Count</strong>
                      </td>
                      <td>
                        <strong>Total Reff. Income</strong>
                      </td>
                    </tr>
                    {
                      topmarketData?.map((d, index) => {
                        return (
                          <tr className="gray zoom" key={index}>
                            <td>{index + 1}</td>
                            <td>{d.id}</td>
                            <td>{window.tronWeb?.address.fromHex(d.wallet)}</td>
                            <td>{d.refCount}</td>
                            <td style={{ color: "green" }}>
                              {d.total_member}{" "}
                            </td>
                            <td>{d.refIncome} BTT </td>
                          </tr>
                        );
                      })}
                    {topmarketData?.length == 0 && (
                      <NoRecord colspan={6} message={"No Record Found !"} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default TopMarketer;
