import React, { useEffect, useState } from "react";

import Loader from "../pages/components/Loader";

import {
  claimAmount,
  getGeometryStructure,
  getUserInfo,
  getUserProgressInfo,
} from "./helpers/getweb3";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  setGeoLength,
  setProgress,
  setUserInfo,
  setUserApiData,
} from "./helpers/redux/dataSlice";
import {
  getTotalTeam,
  getuserDirectIncome,
  getuserGeometryIncome,
  getuserRegistraionDetails,
  getuserStageIncome,
  getuserclaimIncome,
} from "./helpers/api";
import SlotBuy from "./SlotBuy";
import Model from "./Model";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [loadingpage, setLoadingPage] = useState(true);

  const [refreshclaim, setRefreshClaim] = useState(false);
  const { walletAddress, userInfo } = useSelector((state) => state.user.value);
  const [totalTeam, setTotalTeam] = useState([]);
  const [totalDirectIncome, setTotalDirectIncome] = useState("");
  const [totalStageIncome, setTotalStageIncome] = useState("");
  const [totalGeometryIncome, setTotalGeomIncome] = useState("");
  const [totalGeometrySpoIncome, setTotalGeomSpoIncome] = useState("");
  const [totalclaimIncome, setTotalTotalIncome] = useState("");

  useEffect(() => {
    getUserInfo(walletAddress).then((res) => {
      dispatch(setUserInfo({ userInfo: res }));
    });
  }, [walletAddress, refreshclaim]);

  useEffect(() => {
    getGeometryStructure(walletAddress, userInfo.inWhichGeo).then((res) => {
      dispatch(setGeoLength({ geoLength: res?.length }));
    });
    getUserProgressInfo(walletAddress).then((res) => {
      dispatch(setProgress({ progress: res }));
    });
    getTotalTeam(walletAddress).then((res) => {
      setTotalTeam(res?.totalMembers);
    });
  }, [walletAddress, userInfo, refreshclaim]);

  useEffect(() => {
    if (walletAddress) {
      getuserDirectIncome(walletAddress).then((res) => {
        setTotalDirectIncome(
          res.totalIncomeUpline + res.totalIncomedirectReferal
        );
      });
      getuserStageIncome(walletAddress).then((res) => {
        setTotalStageIncome(res.total_amountRecv);
      });
      getuserGeometryIncome(walletAddress).then((res) => {
        setTotalGeomSpoIncome(res.total_sponserGetReward);
        setTotalGeomIncome(res.total_rewardGet);
      });
      getuserclaimIncome(walletAddress).then((res) => {
        setTotalTotalIncome(res?.total_amountclaim);
      });
    }
  }, [walletAddress]);

  useEffect(() => {
    var tt;
    tt = setInterval(() => {
      getuserRegistraionDetails(walletAddress).then((res) => {
        if (res?.length > 0) {
          dispatch(setUserApiData({ userApiData: res[0] }));
          setTimeout(() => setLoadingPage(false), 3000);
          clearInterval(tt);
        }
      });
    }, 2000);

    return () => clearInterval(tt);
  }, [walletAddress, userInfo]);

  if (loadingpage) return <Loader />;
  else {
    return (
      <>
        <Model/>
        <div className="col-12 col-lg-12  mt-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3  mt-3">
              <div className="container_Hide">
                <SlotBuy />
              </div>
            </div>
            <div className="col-12 col-md-6  col-lg-3 mt-3">
              <div className="card border-bottom-0 mb-3">
                <div className="card-content border-bottom border-success border-w-5">
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <div className="circle-50 outline-badge-success">
                        <span className="cf card-liner-icon cf-xmr mt-2">
                          <i class="fas fa-handshake "></i>
                        </span>
                      </div>
                      <div className="media-body align-self-center pl-3">
                        <span className="mb-0 h6 font-w-600">
                          Total Directs{" "}
                        </span>
                        <br />

                        <p className="mb-0 font-w-500 h6">
                          {userInfo?.totaldirects}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-bottom-0 mt-3 mb-3">
                <div className="card-content border-bottom border-info border-w-5">
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <div className="circle-50 outline-badge-info">
                        <span className="cf card-liner-icon cf-xmr mt-2">
                          <i class="fas fa-users"></i>
                        </span>
                      </div>
                      <div className="media-body align-self-center pl-3">
                        <span className="mb-0 h6 font-w-600">Total Team</span>
                        <br />
                        <p className="mb-0 font-w-500 h6">
                          {totalTeam ? totalTeam : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card border-bottom-0 mt-3 mb-3">
                <div className="card-content border-bottom border-info border-w-5">
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <div className="circle-50 outline-badge-info">
                        <span className="cf card-liner-icon cf-xmr mt-2">
                          <i class="fas fa-calendar-minus"></i>
                        </span>
                      </div>
                      <div className="media-body align-self-center pl-3">
                        <span className="mb-0 h6 font-w-600">
                          {" "}
                          Geometry Active Partners
                        </span>
                        <br />
                        <p className="mb-0 font-w-500 h6">0 ARB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6  col-lg-3 mt-3">
              <div className="card border-bottom-0  mb-3">
                <div className="card-content border-bottom border-warning border-w-5">
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <div className="circle-50 outline-badge-warning">
                        <span className="cf card-liner-icon cf-xmr mt-2">
                          <i class="fas fa-donate"></i>
                        </span>
                      </div>
                      <div className="media-body align-self-center pl-3">
                        <span className="mb-0 h6 font-w-600">Total Income</span>
                        <br />
                        <p className="mb-0 font-w-500 h6">
                          {" "}
                          {(
                            Number(totalDirectIncome ? totalDirectIncome : 0) +
                            Number(totalStageIncome ? totalStageIncome : 0) +
                            Number(
                              totalGeometryIncome ? totalGeometryIncome : 0
                            ) +
                            Number(
                              totalGeometrySpoIncome
                                ? totalGeometrySpoIncome
                                : 0
                            )
                          )?.toFixed(2)}{" "}
                          ARB{" "}
                          <img
                            src="/images/zig/arbitrum 1.svg"
                            alt=""
                            height={15}
                            width={15}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-bottom-0 mt-3 mb-3">
                <div className="card-content border-bottom border-danger border-w-5">
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <div className="circle-50 outline-badge-danger">
                        <span className="cf card-liner-icon cf-xmr mt-2">
                          <i class="fas fa-calendar-minus"></i>
                        </span>
                      </div>
                      <div className="media-body align-self-center pl-3">
                        <span className="mb-0 h6 font-w-600">
                          Claim Income (after Deduction)
                        </span>
                        <br />
                        <p className="mb-0 font-w-500 h6">
                          {totalclaimIncome ? totalclaimIncome : 0} ARB{" "}
                          <img
                            src="/images/zig/arbitrum 1.svg"
                            alt=""
                            height={15}
                            width={15}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 ">
              <div className="card mt-3">
                <div className="card-content">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="card-title"> Claim Income</h6>
                  </div>
                  <div className="card-body ">
                    <div className="content">
                      <label htmlFor="">
                        Available Income (ARB){" "}
                        <img
                          src="/images/zig/arbitrum 1.svg"
                          alt=""
                          height={15}
                          width={15}
                        />
                      </label>
                      <input
                        className="form-control"
                        id="reffralcode"
                        readOnly
                        value={
                          Number(userInfo?.SponserincomeFromGeoUpdate / 1e18) +
                          Number(userInfo?.incomeFromGeoUpdate / 1e18) +
                          Number(userInfo?.totalReferalIncome / 1e18)
                        }
                      />
                 
                      <div className="text-center">
                        <button
                          className=" btn btn-copy-gradient w-75 mt-4"
                          onClick={() => {
                            if (
                              userInfo?.totalReferalIncome / 1e18 +
                                userInfo?.incomeFromGeoUpdate / 1e18 +
                                userInfo?.SponserincomeFromGeoUpdate /
                                  1e18 /
                                  1e18 >
                              0
                            ) {
                              console.log("cal:::");
                              if (userInfo?.geometryUnlocked > 0) {
                                if (userInfo?.totaldirects > 2) {
                                } else {
                                  toast.error("3 Direct Required!");
                                }
                              } else {
                                toast.error(
                                  "Please Purchase Geometry First  to claim your all income"
                                );
                              }
                            } else {
                              toast.error("Amount must be greater than 0!");
                            }
                          }}
                          data-toggle="modal"
                          data-target="#staticBackdrop"
                        >
                          {" "}
                          Claim{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {userInfo?.totaldirects > 2 &&
         userInfo?.geometryUnlocked > 0 &&
          userInfo?.totalReferalIncome / 1e18 +
          userInfo?.incomeFromGeoUpdate / 1e18 +
          userInfo?.SponserincomeFromGeoUpdate / 1e18  >
          0 ? (
          <div
            class="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style={{ background: "#171717", border: "1px solid #393737" }}
                >
                  <h5 class="modal-title text-white " id="staticBackdropLabel">
                    Claim Income
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  class="modal-body text-center text-white font-weight-bolder "
                  style={{ background: "#171717" }}
                >
                  Received Total Amount :-
                  <span className="text-info">
                  {Number(userInfo?.SponserincomeFromGeoUpdate / 1e18) +
                    Number(userInfo?.incomeFromGeoUpdate / 1e18) +
                    Number(userInfo?.totalReferalIncome / 1e18)}{" "}
                    </span>
                  ARB{" "}
                  <img
                    src="/images/zig/arbitrum 1.svg"
                    alt=""
                    height={15}
                    width={15}
                  />
                  {/* <p className="pt-1">
                    Withdraw Fee :- <span className="text-danger">5%</span>
                  </p> */}
                  {/* <p className="">
                    Received Total Amt :-
                    <span className="text-info">
                      {Number(userInfo?.SponserincomeFromGeoUpdate / 1e18) +
                        Number(userInfo?.incomeFromGeoUpdate / 1e18) +
                        Number(userInfo?.totalReferalIncome / 1e18) -
                        (Number(userInfo?.SponserincomeFromGeoUpdate / 1e18) +
                          Number(userInfo?.incomeFromGeoUpdate / 1e18) +
                          Number(userInfo?.totalReferalIncome / 1e18)) *
                          (5 / 100)}{" "}
                      ARB{" "}
                      <img
                        src="/images/zig/arbitrum 1.svg"
                        alt=""
                        height={15}
                        width={15}
                      />
                    </span>
                  </p> */}
                </div>
                <div
                  class="modal-footer d-flex justify-content-between"
                  style={{ background: "#171717" }}
                >
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-info"
                    data-dismiss="modal"
                    onClick={() => claimAmount(walletAddress, setRefreshClaim)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
};

export default Dashboard;
