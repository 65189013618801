import React from "react";
function Penta(props) {
      // console.log(props)
  return (
    <svg
      width="540"
      height="540"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <g>
        <g id="penta">
          <g id="svg_46">
            <polygon
              className="st2"
              points="256,80.9 71.8,214.7 142.2,431.1 369.8,431.1 440.2,214.7   "
              id="svg_47"
              fill="#231F20"
            />
            <g id="svg_48">
              <g id="svg_49">
                <g id="svg_50">
                  <path
                    className="st3"
                    d="m253.3,153.7c-30.7,0 -55.8,25.1 -55.8,55.8s25.1,55.8 55.8,55.8s55.8,-25.1 55.8,-55.8s-25.1,-55.8 -55.8,-55.8z"
                    id="svg_51"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
              <g id="svg_52">
                <g id="svg_53">
                  <path
                    className="st3"
                    d="m349,309.9c-1.5,-3.7 -3.4,-7.1 -5.6,-10.2c-11.2,-16.6 -28.5,-27.5 -48,-30.2c-2.4,-0.2 -5.1,0.2 -7.1,1.7c-10.2,7.6 -22.4,11.5 -35.1,11.5c-12.7,0 -24.9,-3.9 -35.1,-11.5c-1.9,-1.5 -4.6,-2.2 -7.1,-1.7c-19.5,2.7 -37,13.6 -48,30.2c-2.2,3.2 -4.1,6.8 -5.6,10.2c-0.7,1.5 -0.5,3.2 0.2,4.6c1.9,3.4 4.4,6.8 6.6,9.7c3.4,4.6 7.1,8.8 11.2,12.7c3.4,3.4 7.3,6.6 11.2,9.7c19.3,14.4 42.4,21.9 66.3,21.9c23.9,0 47,-7.6 66.3,-21.9c3.9,-2.9 7.8,-6.3 11.2,-9.7c3.9,-3.9 7.8,-8 11.2,-12.7c2.4,-3.2 4.6,-6.3 6.6,-9.7c1.3,-1.4 1.6,-3.1 0.8,-4.6z"
                    id="svg_54"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

       {props.length>=1 ?
          <g id="svg_81">
            <text
              fill="#000000"
              stroke="#cbb42a"
              x="109.5"
              y="475.8"
              id="svg_61"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              xmlSpace="preserve"
            >
             1 . {props.users.length>=1 ? props.users[0]?.slice(0,4)+"..." +props.users[0]?.slice(-4) :null}
            </text>
            <g id="svg_74">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                stroke="#3f6d23"
                fill="#fff"
                cx="135.20501"
                cy="444.7853"
                id="svg_72"
                rx="14.49968"
                ry="13.74969"
              />
              <path
                id="svg_73"
                d="m138.94465,443.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                stroke="#000"
                fill="#000000"
              />
            </g>
          </g>
        :null}
        {/* #2 */}
        {props.length>=2 ?
          <g id="svg_70">
            <ellipse
              strokeDasharray="2,2"
              strokeWidth="2"
              stroke="#3f6d23"
              fill="#fff"
              cx="55.20501"
              cy="214.7853"
              id="svg_68"
              rx="14.49968"
              ry="13.74969"
            />
            <path
              id="svg_69"
              d="m58.94465,213.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
              stroke="#cbb42a"
              fill="#bde529"
            />
            <text
              fill="#000000"
              stroke="#cbb42a"
              x="23.5"
              y="249.8"
              id="svg_62"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              xmlSpace="preserve"
            >
            2 .  {props.users.length>=2 ? props.users[1]?.slice(0,4)+"..." +props.users[1]?.slice(-4) :null}
            </text>
          </g>
          :null}
        {/* #3 */}

        {props.length>=3 ?
          <g id="svg_78">
            <text
              fill="#000000"
              stroke="#cbb42a"
              x="278.88236"
              y="70.71177"
              id="svg_63"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              xmlSpace="preserve"
            >
              3 . {props.users.length>=3 ? props.users[2]?.slice(0,4)+"..." +props.users[2]?.slice(-4) :null}
            </text>
            <g id="svg_67">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                stroke="#3f6d23"
                fill="#fff"
                cx="255.20501"
                cy="65.7853"
                id="svg_58"
                rx="14.49968"
                ry="13.74969"
              />
              <path
                id="svg_66"
                d="m258.94465,64.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                stroke="#cbb42a"
                fill="#000000"
              />
            </g>
          </g>
          :null}
        {/* #4 */}

        {props.length>=4 ?
          <g id="svg_79">
            <text
              fill="#000000"
              stroke="#cbb42a"
              x="437.2647"
              y="250.65292"
              id="svg_64"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              xmlSpace="preserve"
            >
              4 . {props.users.length>=4 ? props.users[3]?.slice(0,4)+"..." +props.users[3]?.slice(-4) :null}
            </text>
            <g id="svg_89">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                stroke="#3f6d23"
                fill="#fff"
                cx="456.20501"
                cy="214.7853"
                id="svg_59"
                rx="14.49968"
                ry="13.74969"
              />
              <path
                id="svg_71"
                d="m459.94465,213.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                stroke="#cbb42a"
                fill="#000000"
              />
            </g>
          </g>
          :null}
        {/* #5 */}

        {props.length>=5 ?
          <g id="svg_80">
            <text
              fill="#000000"
              stroke="#cbb42a"
              x="348.5"
              y="476.8"
              id="svg_65"
              fontSize="16"
              fontFamily="sans-serif"
              textAnchor="start"
              xmlSpace="preserve"
            > 
           5 . {props.users.length>=5 ? props.users[4]?.slice(0,4)+"..." +props.users[4]?.slice(-4) :null}
            </text>
            <g id="svg_77">
              <ellipse
                strokeDasharray="2,2"
                strokeWidth="2"
                stroke="#3f6d23"
                fill="#fff"
                cx="374.20501"
                cy="445.7853"
                id="svg_75"
                rx="14.49968"
                ry="13.74969"
              />
              <path
                id="svg_76"
                d="m377.94465,444.45591c0,0 0.5355,-1.80091 0,-2.41096c-0.53613,-0.61005 -0.74996,-1.01641 -1.92882,-1.30733s-0.74933,-0.23294 -1.60651,-0.20318c-0.85718,0.02925 -1.57182,0.40636 -1.57182,0.60954c0,0 -0.5355,0.02925 -0.74933,0.20369c-0.21445,0.17445 -0.57146,0.98716 -0.57146,1.19086s0.1785,1.569 0.357,1.8594l-0.21256,0.05798c-0.1785,1.68444 0.714,1.88813 0.714,1.88813c0.32105,1.569 0.64273,0.90097 0.64273,1.30733s-0.32168,0.26167 -0.32168,0.26167s-0.2851,0.63878 -0.9991,0.87121c-0.714,0.23191 -4.67761,1.48075 -4.99992,1.74242c-0.32231,0.26218 -0.28573,1.48177 -0.28573,1.48177l16.99669,0c0,0 0.03721,-1.21959 -0.2851,-1.48177c-0.32294,-0.26218 -4.28654,-1.5105 -5.00055,-1.74242c-0.714,-0.23242 -0.9991,-0.87121 -0.9991,-0.87121s-0.32168,0.14469 -0.32168,-0.26167s0.32168,0.26167 0.64336,-1.30733c0,0 0.89187,-0.20369 0.714,-1.88813l-0.21445,0l0.00003,0z"
                stroke="#000"
                fill="#000000"
              />
            </g>
          </g>
          :null}
        {/* {renderItems()} */}
      </g>
    </svg>
  );
}
export default Penta;
