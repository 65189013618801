import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { setUserExist, setWalletAddress } from "../helpers/redux/dataSlice";

const HeaderMenu = (props) => {
  const { walletAddress, userInfo, userApiData } = useSelector(
    (state) => state.user.value
  );
  const { sponserAddress, sponserId } = userInfo;
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log(userApiData,"userApiData")
  return (
    <div className="navbar-right ml-auto h-100">
      <ul className="ml-auto p-0 m-0 list-unstyled d-flex top-icon h-100">
        <li className="dropdown align-self-center d-inline-block">/</li>

        <li className="dropdown user-profile align-self-center d-inline-block">
          <a
            href="#"
            className="nav-link py-0"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="media">
              <i
                class="fas fa-user-circle"
                style={{ fontSize: "29px", color: "#cbb42a" }}
              ></i>
              {/* <img
                src="./wy.png"
                alt=""
                className="d-flex img-fluid rounded-circle"
                width="29"
              /> */}
            </div>
          </a>

          <div className="dropdown-menu border dropdown-menu-right p-0">
            <a href="" className="dropdown-item px-2 align-self-center d-flex">
              <span className="icon-globe mr-2 h6 mb-0">
                <i class="fas fa-wallet"></i> WalletAddress
              </span>{" "}
              {walletAddress?.slice(0, 14) + "...." + walletAddress?.slice(-14)}
            </a>
            <a href="" className="dropdown-item px-2 align-self-center d-flex">
              <span className="icon-globe mr-2 h6 mb-0">
                <i class="fas fa-user"></i> Referal Wallet :-{" "}
              </span>{" "}
              {sponserAddress?.slice(0, 14) +
                "...." +
                sponserAddress?.slice(-14)}
            </a>
            <a href="" className="dropdown-item px-2 align-self-center d-flex">
              <span className="icon-globe mr-2 h6 mb-0">
                <i class="fas fa-user-plus"></i> Referal Id :-{" "}
              </span>{" "}
              {userApiData?.sponcer_random_id}
            </a>
            <div className="dropdown-divider"></div>
            <div
              className="dropdown-item px-2  logout align-self-center d-flex cursor-pointer"
              onClick={() => {
                localStorage.removeItem("wallet_local");
                dispatch(setWalletAddress({ walletAddress: "" }));
                dispatch(setUserExist({ userExist: false }));
                toast.success("Logout SuccessFul !!");
                history.push("/login");
              }}
            >
              <span className="icon-logout  mr-2 h6  mb-0">
                {" "}
                <i class="fas fa-sign-out-alt"></i>
              </span>{" "}
              Sign Out
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeaderMenu;
